import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer'
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import "../assets/css/BackendStyle.css";
import { BASE_URL } from "../env_config";
import { RemoveCookies,GetCookies } from "../_metronic/_helpers/Cookies";
import {
    Builder,
    Dashboard,
    UploadDiamond,
    SearchDiamond,
    SearchResult,
    InvalidDiamond,
    UploadHistory,
    HoldRequest,
    ConfirmHistory,
    SalesHistory,
    DiamondFeed,
    UserProfilePage,
    Setting,
    Help
} from "../_metronic/_partials";
import {
    KycHelp,
    UploadMode,
    FtpModeHelp,
    ApiHelp,
    FileModeHelp,
    ParameterHelp,
    LabParameterHelp,
    SearchDiamHelp,
    OrderHelp,
    ContactMail,
    LabApiHelp
 } from "../_metronic/_partials/help/Component";
 
export default function BasePage(props) {
    const onIdle = () => {
        GetCookies("ReMe").then(response => {
            if(response == "false"){
                localStorage.clear()
                RemoveCookies()
                // console.log("logout")
                var cookies = document.cookie.split(";");
                for(var i=0; i < cookies.length; i++) {
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
                window.location.replace(`${BASE_URL}/logout`);
            }else{
                // console.log("remember")
            }
          })
    }


    const { getRemainingTime } = useIdleTimer({
        onIdle,
        // timeout: 120000,
        timeout: 3600000,
        throttle: 500
      })
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from={`${props.userinfo.type ?  "/" : "/profilesetup"}`} to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={() => <Dashboard subId={props.subId} userinfo={props.userinfo}/>} />
                <ContentRoute path="/builder" component={Builder} />
                <ContentRoute path="/upload-diamond" component={() => <UploadDiamond subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/search-diamond" component={() => <SearchDiamond subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/search-result" component={()=> <SearchResult subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/invalid-diamond"  component={() => <InvalidDiamond subId={props.subId} userinfo={props.userinfo}/>} />
                <ContentRoute path="/upload-history" component={() => <UploadHistory subId={props.subId} userinfo={props.userinfo}/>} />
                <ContentRoute path="/hold-request" component={() => <HoldRequest subId={props.subId} userinfo={props.userinfo}/>} />
                <ContentRoute path="/confirm-history" component={() => <ConfirmHistory subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/sales-history" component={() => <SalesHistory subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/diamond-feed" component={() => <DiamondFeed subId={props.subId} userinfo={props.userinfo}/>} />
                <ContentRoute path="/profile" component={() => <UserProfilePage subId={props.subId} userinfo={props.userinfo} companyRef={props.companyRef}/>}/>
                <ContentRoute path="/settings" component={() => <Setting subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/help" component={() => <Help subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/kyc-help" component={KycHelp} />
                <ContentRoute path="/uploadmode-help" component={() => <UploadMode subId={props.subId} userinfo={props.userinfo}/>} />
                <ContentRoute path="/ftpmode-help" component={() => <FtpModeHelp subId={props.subId} userinfo={props.userinfo}/>}/>
                <ContentRoute path="/apimode-help" component={ApiHelp} />
                <ContentRoute path="/lab-apimode-help" component={LabApiHelp} />
                <ContentRoute path="/filemode-help" component={() => <FileModeHelp subId={props.subId} userinfo={props.userinfo} />}/>
                <ContentRoute path="/parameter-help" component={ParameterHelp} />
                <ContentRoute path="/lab-parameter-help" component={LabParameterHelp} />
                <ContentRoute path="/search-help" component={SearchDiamHelp} />
                <ContentRoute path="/order-help" component={OrderHelp} />
                <ContentRoute path="/contact-help" component={() => <ContactMail subId={props.subId} userinfo={props.userinfo} />} />
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
