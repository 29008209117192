import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import {BASE_URL} from "../../../env_config"
import { DropzoneArea } from 'material-ui-dropzone';
// import { toAbsoluteUrl } from "../../_helpers";
import axios from 'axios';
import { NODE_API_URL } from '../../../env_config';
import moment from "moment-timezone";
import { ProgressBar } from "react-bootstrap";  

export const FileMode = ({userinfo}) => {
// const [csvFile,setCsvFile] =useState();
const [count,setCount] =useState([]);
const [successMsg,setSuccessMsg] =useState();
const [processing ,setProcessing] =useState(false);
const [error ,setError] =useState(false);
const [inProcess ,setInProcess] =useState(false);
const [loader ,setLoader] =useState(true);
const [progress, setProgress] = useState(0);
const [loading, setLoading] = useState(false);
const [percent, setPercent] = useState(0);
// console.log(userinfo.id)
useEffect(() => {
   
    axios({
        method: "GET",
        url: `${NODE_API_URL}/SUPP/upload-history`,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
          },
    }).then((res) => {
        // setLoader(false)
        if (res && res.data) {
            setCount(res.data.data[0])
        }
    }).catch(error => {
        // setLoader(false)
        if(error?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
    })

    axios({
        method: "GET",
        url: `${NODE_API_URL}/SUPP/file-upload-failed-data`,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
          },
    }).then((res) => {
        
        setLoader(false)
        if (res && res.data.data) {
            // console.log(res.data.data[0].upload_status)
            if(res.data.data[0].upload_status ==="Error"){
                setError(true)
            }
        }

    }).catch(error => {
        setLoader(false)
        if(error?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
        // console.log(error,"ERROR")
    })
 
    axios({
        method: "GET",
        url: NODE_API_URL + "/SUPP/file-status", 
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
          },
      }).then((res) => {
          setLoader(false)
         
        if (res && res.data ) {
            if(res.data.upload_status === "pending") { 
                setProcessing(true) 
            } else if(res.data.upload_status === "Processing") { 
                setInProcess(true)
            } else if(res.data.upload_status === "Error") { 
                setError(true)
            } 
        }
      }).catch((res) => {
        setLoader(false)
        if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
    });

},[userinfo.id,userinfo.supplierName])

    function handleSave(files) {

        if(files.length !== 0 ){
            // console.log("files",files[0].path)
            const filename = files[0].path
            const file = new File(files, filename);
            const formData = new FormData();

            formData.append("csvFile", [file][0])
            const DataObj = {
                supp_id:userinfo.id,
                upload_status:"pending",
            }

            for ( var key in DataObj ) {
                formData.append(key, DataObj[key]);
            }

            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/upload-file`,
                headers: {
                  'Content-Type': 'multipart/form-data',
                  "Access-Control-Allow-Origin": "*",
                   "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                data: formData,
              }).then(res => {
                setSuccessMsg(res.data.msg)
                setProcessing(true)
                setLoading(true)
                setProgress(parseInt(progress, 10) + 5);
                setPercent(parseInt((progress / 100) * 100, 10));
              }).catch(err => {
                // console.error("err",err.response.data)
                if(err?.response?.data?.message === "UnAuthorized!"){
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                  }
              })
        }
        
    }

    return (
        <>
            <div className="card-body pb-10">
                <div className="row">
                    <div className="col-12">
                    {successMsg ? (
                        <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
                            <div className="alert-text font-weight-bold">
                                {successMsg}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    </div>
                    <div className="col-12">
                        {!loader ?
                            <>
                                {processing ? 
                                    <div className="mb-5 alert alert-custom alert-light-warning alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                            Your request is in queue, please wait for some time
                                        </div>
                                    </div>
                                : inProcess ?
                                    <div className="mb-5 alert alert-custom alert-light-warning alert-dismissible">
                                        <div className="alert-text font-weight-bold">
                                            Your request is in being processed, please wait for some time
                                        </div>
                                    </div>
                                : error ?
                                        <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
                                            <div className="alert-text font-weight-bold">
                                                There is some issue in your last uploaded file, please try again
                                            </div>
                                        </div>
                                :
                                    <>
                                        {count ? 
                                            <>
                                                {count.stockStatus === "Expired" ?
                                                    <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
                                                        <div className="alert-text font-weight-bold">
                                                            Your Stock was Expired on {count.expireTime ? moment.tz(count.expireTime, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                                                        </div>
                                                    </div> 
                                                :
                                                    <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
                                                        <div className="alert-text font-weight-bold">
                                                            {/* {JSON.stringify(count)} */}
                                                            <Link className="text-success" to="/search-diamond">Active stones: {count.active}</Link>, <Link className="text-danger" to="/invalid-diamond">Invalid Stones: {count.inactive}</Link> 
                                                        </div>
                                                    </div> }
                                            </> :""
                                        }
                                    </>
                                }
                        </> :""}
                    </div>
                    <div className={`col-12 mt-5 FileMode ${processing || inProcess ? "fileModeCursorDisable" : "" }`} >
                        <div className={`${processing || inProcess ? "fileModeDisable" : ""}`}>
                            <DropzoneArea
                                // onSave={handleSave}
                                acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                onChange={handleSave}
                                showPreviews={false}
                                maxFileSize={2100000000}
                                showFileNames={true}
                                filesLimit={1}
                                showFileNamesInPreview={true}
                                // showPreviewsInDropzone={true}
                                previewText={true}
                                dropzoneText={<span className="mt-5">Drag & Drop or Browse file <br /> <span className="small font-italic text-muted">File Format : csv, xls, xlsx</span ></span>}
                            />
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        {loading ? <ProgressBar now={percent} label={`${percent}%`} /> : ""}
                    </div>
                    <div className="col-md-12 mt-8 d-md-flex justify-content-between">
                        <div><Link to="/filemode-help">Read File Documentation</Link> to Upload Diamonds using File (File Format : csv, xls, xlsx).</div>
                        {/* <a href={`${BASE_URL}/assets/doc_file.csv`} download="doc_upload_file.csv">Download Sample File from here.</a> */}
                        <a href={`${BASE_URL}/media/sample/${userinfo.type === "Natural" ? "Natural_doc_file" : "Lab_doc_file"}.csv`} download="doc_upload_file.csv">Download Sample File from here.</a>
                    </div>
                    <div className="col-md-8 mt-5 mb-5">
                        <div className="border-warning font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-warning-o-20" style={{borderLeft:"5px solid"}}><span className="font-weight-bold">Note:</span> If you would like to change your upload method, please contact your purchase representative</div>
                    </div>
                </div>
            </div>
        </>
    );
};
