import React from "react";
import {  Switch,Redirect, Route } from 'react-router-dom';
import Home from "./Home";
import Login from "./Login";
// import ProfileSetup from "./ProfileSetup";
import ForgotPassword from "./ForgotPassword";
// import ResetPssword from "./ResetPassword";
import VeryfyMail from "./VerifyMail";
// import SwitchVerifyTemp from "./SwitchVerifyTemp";
// import SwitchVerify from "./SwitchVerify";
// import SwitchRejectTemp from "./SwitchRejectTemp";
// import SwitchReject from "./SwitchReject";
import Notfound from "./Notfound";
import ResendVerification from "./ResendVerification";
import Terms from "./Terms";
import Privacy from "./Privacy";
// import { ContentRoute } from "../../../../_metronic/layout";
import Registration from "./Registration";
const HomeRoutes = () =>{
  return(
    <>
      <Switch>
          <Route path="/auth/Home" component={Home} />
          <Route exact path="/" component={Home} />
          <Route exact path="/auth/login" component={Login} title="Login - Diamonds on Call Ltd - Suppliers Centre"/>
          {/* <Route path="/auth/resetpassword/:token" component={ResetPssword} title="Reset - Diamonds on Call Ltd - Suppliers Centre"/> */}
          <Route path="/auth/verifymail/:token" component={VeryfyMail} title="Verify - Diamonds on Call Ltd - Suppliers Centre"/>
          {/* <Route path="/auth/verifyaccount/:token" component={SwitchVerifyTemp} title="Verify - Diamonds on Call Ltd - Suppliers Centre"/> */}
          {/* <Route path="/auth/verifyswitch/:token" component={SwitchVerify} title="Verify - Diamonds on Call Ltd - Suppliers Centre"/> */}
          {/* <Route path="/auth/rejectaccount/:token" component={SwitchRejectTemp} title="Verify - Diamonds on Call Ltd - Suppliers Centre"/> */}
          {/* <Route path="/auth/rejectswitch/:token" component={SwitchReject} title="Verify - Diamonds on Call Ltd - Suppliers Centre"/> */}
          <Route exact path="/auth/forgot-password" component={ForgotPassword} title="Forgot Password - Diamonds on Call Ltd - Suppliers Centre"/>
          <Route exact path="/terms" component={Terms} title="Terms and Conditions - Diamonds on Call Ltd - Suppliers Centre"/>
          <Route exact path="/privacy" component={Privacy} title="Privacy Policy - Diamonds on Call Ltd - Suppliers Centre"/>
          <Route exact path="/auth/resend-verification" component={ResendVerification} title="Resend verification - Diamonds on Call Ltd - Suppliers Centre"/>
          <Route exact path="/auth/registration" component={Registration} title="Signup - Diamonds on Call Ltd - Suppliers Centre"/>
          <Redirect from="/auth" exact={true} to="/auth/login" />
          <Redirect to="/auth/login" />
          <Route component={Notfound}/>
        </Switch>
    </>
  )
}
export default HomeRoutes;