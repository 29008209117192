import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import moment from "moment-timezone";


export const HoldListColumn = (userinfo) => [
    {
        Header: "Date & Time",
        // accessor: "cd",
        accessor: (d) => {
            return (
                <>
                    {  d.cd ? moment.tz(d.cd, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Order ID",
        accessor: "confirmGoodsId",
        className: "text-center align-middle",
    },
    {
        Header: "Diamond Detail",
        accessor: (d) => (
            <>
            {d.diamondType === "PN" || d.diamondType === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.cShape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.cShape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamondType}_diamond mr-2 small`}>
                                {d.diamondType}
                            </span>
                            <span className={`text-primary small ml-3`}>
                                {d.diamondType}-{d.parcelId}-{d.splitId}{d.cgSplitId ?<>/{d.cgSplitId}</> : ""}
                            </span>
                            <span className={`text-primary text-capitalize small ml-3`}>
                                {d.lengthMm} - {d.widthMm} MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor}{" "}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.awsImage &&  d.awsImage !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={d.awsImage}
                                width="50px"
                                onError={toAbsoluteUrl(
                                    `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "W" || d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy" ? "F" : "W" : d.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-2 small`}>
                                {d.diamondType === "W" || d.diamondType === "N" ? d.C_Color === "fancy"  ? "NF" : "N" : d.C_Color === "fancy" ? "LF" : "L"}
                            </span>
                            <span  className={`text-primary small ml-3`}>
                                {d.loatNO}
                            </span>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary mx-2 small"
                                href={
                                    d.lab === "IGI"
                                        ? `https://www.igi.org/viewpdf.php?r=${d.certiNo}`
                                        : d.lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${d.certiNo}`
                                        : d.lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.certiNo}`
                                        : d.lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.certiNo}`
                                        : d.Certi_link
                                }
                            >
                                {d.lab}-{d.certiNo}
                            </a>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {(d.cWeight).toFixed(2)}Ct.{" "}
                            {d.cColor === "fancy" ? (
                                <>
                                    {d.fIntensity}{" "}{d.fOvertone}{" "}{d.fColor}{" "}
                                </>
                            ) : (
                                <>{d.cColor}{" "}</>
                            )}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle text-nowrap",
    },  
  
    {
        Header: "Price",
        className: "text-right  text-nowrap align-middle pr-5",
        accessor: (d) => (
            <>
                <span className="font-weight-bolder text-primary">
                    ${parseFloat(d.oRate * d.cWeight).toFixed(2)}
                </span>{" "}
                <br />
                <span className="text-muted small">
                    $/CT ${parseFloat(d.oRate).toFixed(2)}
                </span>
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
       
    },
    
];
