import React, { useMemo, useState,useEffect } from "react";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
// import DummyData from "../../dummyData/holdCancelledList.json";
import { HoldListColumn } from "./Columns";
import { GlobleFilter } from "../../tableFilter";
// import {DiamDetail} from "../../popup/DiamDetail"
import {HoldApprove} from "../../popup/HoldApprove"
import {HoldCancel} from "../../popup/HoldCancel"
import { SwalWarn } from "../../popup";
import ExpandedDetails from "../../expandedDetails/ExpandedDetails"
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../../env_config';
import { Dropdown, Modal } from 'react-bootstrap';
import { FaSortDown,FaSortUp,FaAngleLeft,FaAngleRight,FaAngleDoubleLeft,FaAngleDoubleRight,FaChevronDown, FaChevronRight,FaEllipsisV} from "react-icons/fa";
import HoldSkeleton from "../../SkeletonLoaders/HoldSkeleton";
import PerfectScrollbar from "react-perfect-scrollbar";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export const HoldList = (userinfo) => {
    // console.log(userinfo)
    const [loader,setLoader] = useState(true)
    const [data,setData] = useState([])
    const [showDiamData,setShowDiamData] = useState([])
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);
    const [temp,setTemp] = useState(true)
    const [successMsg,setSuccessMsg]=useState('')
    const [errorMsg,setErrorMsg]=useState('')
    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <ExpandedDetails row={row.row.original} userinfo={userinfo.userinfo} />
            </>
        ),[]
      )
    const [showHoldAprrovePopup,setShowHoldAprrovePopup] = useState(false)
    const [showHoldCancelPopup,setShowHoldCancelPopup] = useState(false)

    useEffect(() => {
        
 
        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/hold-List`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
        }).then((res) => {
            if (res && res.data) {
                setLoader(false)
                // console.log("resss",res.data.data)
                setData(res.data.data)
                setTimeout(() => {
                    setErrorMsg('')
                    setSuccessMsg('')
                }, 15000);
            }
        }).catch((res) => {
            setLoader(false)
            if(res?.response?.data?.message === "UnAuthorized!"){
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });

    }, [userinfo.userinfo.supplierName,temp]);
    const columns = useMemo(() => HoldListColumn(userinfo.userinfo), [userinfo.userinfo]);

    const ConfirmHold = (row) =>{
        // setLoader(true)
        const finalData = {
            "Certi_NO":row.original.certiNo
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/hold-status`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: finalData,
        }).then((res) => {
            if (res && res.data) {
                // setLoader(false)
                if(res.data.data[0].supStatus == "3"){
                    setSwalWarnOpen(true)
                    setAlertMessage("Stone is already approved")
                } else if (res.data.data[0].supStatus == "2" || res.data.data[0].oSupplierStatus == "2"){
                    setSwalWarnOpen(true)
                    setAlertMessage("Stone is already Cancelled")
                    // alert("Stone is already Cancelled")
                } else{
                    setShowHoldAprrovePopup(true)
                    setShowDiamData(res.data.data[0])
                }
               // console.log("resss",res.data.data)
                // setData(res.data.data)
            }
        }).catch((res) => {
            setLoader(false)
            if(res?.response?.data?.message === "UnAuthorized!"){
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });
       // console.log("confirm row",row.original.CertiNo)
    }
    
    const CancelHold = (row) =>{
        // setLoader(true)
        const finalData = {
            "Certi_NO":row.original.certiNo
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/hold-status`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: finalData,
        }).then((res) => {
            if (res && res.data) {
                // setLoader(false)
                if(res.data.data[0].supStatus == "3"){
                    setSwalWarnOpen(true)
                    setAlertMessage("Stone is already approved")
                } else if (res.data.data[0].supStatus == "2" || res.data.data[0].oSupplierStatus == "2"){
                    setSwalWarnOpen(true)
                    setAlertMessage("Stone is already Cancelled")
                } else{
                    setShowHoldCancelPopup(true)
                    setShowDiamData(res.data.data[0])
                }
              //  console.log("resss cancel",res)
                // setData(res.data.data)
            }
        }).catch((res) => {
            setLoader(false)
            if(res?.response?.data?.message === "UnAuthorized!"){
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });
      //  console.log("cancel row",row.original.CertiNo)
        
    }

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    // {
                    //     Header: () => null,
                    //     id: "expander",
                    //     Cell: ({ row }) => (
                    //         <span>
                    //             <FaEye className="text-hover-primary cursor-pointer" onClick={() => showDiamDetail(row)}/>
                    //         </span>
                    //     ),
                    // },
                    {
                        Header: () => null,
                        id: 'expander',
                        className:"expander",
                        Cell: ({ row }) => (
                            <span  {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand" />}
                            </span>
                        ),
                    },
                    {
                        Header: "Action",
                        id: "Action",
                        className: "text-center align-middle Action",
                        Cell: ({ row }) => (
                            <>
                            {
                                row.original.supStatus == "3"
                                    ? <span className="label label-lg label-outline-success label-pill label-inline bg-success-o-20 font-weight-bold text-nowrap">Approved</span>
                                    : row.original.supStatus == "2" || row.original.oSupplierStatus == "2"
                                    ? <><span className="label label-lg label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold text-nowrap">{ row.original.oSupplierStatus != "2" ? "Released" : "Cancelled"}</span></>
                                    : row.original.supStatus == "1" ?
                                    <>
                                        {userinfo.editPerm || !userinfo.subId ?
                                            <div className="btn-group btn-group-sm" role="group" >
                                                <button type="button" className="btn btn-primary" onClick={() => ConfirmHold(row)}>Approve</button>
                                                <button type="button" className="btn btn-danger" onClick={() => CancelHold(row)}>Cancel</button>
                                            </div>
                                        :"-"}
                                    </>
                                    : row.original.status == "4"
                                    ? <span className="label label-lg label-outline-danger label-pill label-inline bg-danger-o-20 font-weight-bold text-nowrap">Hold Release re</span>
                                    : "-"
                            }
                            </>
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );
    return (
        <> {!loader  ? 
            <div className="card-body p-0">
                {successMsg?
                    <div className="mb-5 alert alert-custom alert-light-success alert-dismissible ">
                        <div className="alert-text font-weight-bold">
                            {successMsg}
                        </div>
                    </div>
                :""}
                {errorMsg?
                    <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible ">
                        <div className="alert-text font-weight-bold">
                            {errorMsg}
                        </div>
                    </div>
                :""}
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="font-weight-bolder font-size-h5 text-primary">Hold List</div>
                    <div className="d-flex" style={{width:"200px"}}>
                        <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter}  />
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2 searchResult">
                                    <PerfectScrollbar
                                        options={perfectScrollbarOptions}
                                        className="scroll"
                                        style={{ maxHeight: "400px", position: "relative" }}
                                    >
                                    {/* <div  className="" > */}
                                        <div>
                                            <label className="checkbox my-2 border p-2">
                                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                <span className="mr-2"></span>
                                                Show All
                                            </label> 
                                        </div>
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id} className={`${column.id}`}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    {/* </div> */}
                                    </PerfectScrollbar>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="table-responsive custom-scrollbar w-100 mt-4">
                    <table className="table table-striped table-hover custom_datatable" {...getTableProps()} >
                            <thead className="custom_datatable_head">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(
                                        (column, i) => (
                                            <th key={i} className="text-center text-nowrap align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                                <span className="text-secondary">
                                                    {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                </span>
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {data.length !== 0 ? page.map((row) => {
                                prepareRow(row)
                                return(
                                    <React.Fragment key={row.id}>
                                        <tr {...row.getRowProps()} key={row.id}>
                                            {row.cells.map((cell,i) => {
                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })} 
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                )
                            }) :
                            <tr>
                                <td className="text-center align-middle"colSpan="100%">
                                    No data found
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="table_page_length">
                        Page {pageIndex + 1} of {pageOptions.length}{" "}
                    </span>
                    <div>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage} >
                            <FaAngleDoubleLeft />
                        </button>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage} >
                            <FaAngleLeft />
                        </button>
                        <span className="font-weight-bold text-muted">
                            <input
                                className="form-control d-inline-block form-control-sm"
                                type="number"
                                value={pageIndex + 1}
                                // defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(pageNumber);
                                }}
                                style={{ maxWidth: "70px" }}
                            /> of {pageOptions.length} 
                        </span>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage} >
                            <FaAngleRight />
                        </button>
                        <button className="btn btn-sm btn-light" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >
                            <FaAngleDoubleRight />
                        </button>
                    </div>
                    <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">
                            Rows Per page:{" "}
                        </span>
                        <select
                            className="form-select ml-2 custom-select py-1"
                            value={pageSize}
                            onChange={(e) =>
                                setPageSize(Number(e.target.value))
                            }
                        >
                            {[10, 25, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                {/* <DiamDetail show={showDiamPopup} onHide={() => setShowDiamPopup(false)} data={showDiamData}/> */}
                <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} responseOnOk={()=>window.location.reload()}/> 
                <HoldApprove show={showHoldAprrovePopup} onHide={() => setShowHoldAprrovePopup(false)} data={showDiamData} setTemp={setTemp} setSuccessMsg={setSuccessMsg} temp={temp} setLoader={setLoader}/>
                <HoldCancel show={showHoldCancelPopup} onHide={() => setShowHoldCancelPopup(false)} data={showDiamData} setTemp={setTemp} setErrorMsg={setErrorMsg} temp={temp} setLoader={setLoader}/>
            </div>
            : 
            <HoldSkeleton />
                // <div className="card-body p-0 d-flex justify-content-center font-weight-bold font-size-lg">
                //     Loading...<div className="spinner spinner-primary ml-5"></div>
                // </div>
            }
        </>
    );
};
