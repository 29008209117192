import React from "react";
import moment from "moment-timezone";


export const pendingFeedColumn = (userinfo) => [
    {
        Header: "Requested ID",
        accessor: "srId",
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Customer Status",
        accessor: (d) => {
            return (
                <>
                    {d.api === "0" ?  <div className="d-flex align-items-center justify-content-center"><span className="label label-dot label-danger"></span><span className="text-danger pl-3 font-weight-bolder">API turn Off by admin</span></div>
                        : d.apiOnOff == "1" ? <div className="d-flex align-items-center justify-content-center"><span className="label label-dot label-success"></span><span className="text-success pl-3 font-weight-bolder">Active</span></div>
                        : <div className="d-flex align-items-center justify-content-center"><span className="label label-dot label-danger"></span><span className="text-danger pl-3 font-weight-bolder">InActive</span></div>
                    }
                </>
            );
        },
        className: "text-center align-middle",
    },
    {
        Header: "Country" ,
        accessor: "country",
        className: "text-center align-middle",
        
    },
    {
        Header: "Request Id Timestamp",
        accessor: (d) => {
            return (
                <>
                    {  d.updatedDate ? moment.tz(d.updatedDate, userinfo.timezone ? userinfo.timezone : "Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className: "text-center align-middle",
    }
];
