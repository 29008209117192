import React from "react";

export const PersonaInformation = (props) => {
    return (
        <div className="card card-custom card-stretch">
            <div className="card-header py-3">
                <div className="card-title"> Personal Information </div>
            </div>
            <div className="card-body">
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Email Address:
                    </div>
                    <div className="col-lg-9 col-xl-10">{props.user?.email}</div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        First Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.firstName}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Last Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.lastName}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Designation:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.designation}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Mobile:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.mobileNo}
                    </div>
                </div>
            </div>
        </div>
    );
};
