import React, { useMemo, useEffect,useState } from "react";
import { useSubheader } from "../../../layout";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
// import DummyData from "../dummyData/uploadhistory.json";
import { InvalidDiamondColumn } from "./Columns";
import { GlobleFilter } from "../../tableFilter";
import { Dropdown, Modal } from 'react-bootstrap';
import { FaSortDown,FaSortUp,FaAngleLeft,FaAngleRight,FaAngleDoubleLeft,FaAngleDoubleRight,FaEllipsisV} from "react-icons/fa";
import Excel from "exceljs";
import axios from 'axios';
import FileSaver from "file-saver"
import { BASE_URL, NODE_API_URL } from '../../../../env_config';
import DiamFeedSk from "../../SkeletonLoaders/DiamondFeed";

import PerfectScrollbar from "react-perfect-scrollbar";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const ErrorHistory = ({userinfo,subId}) => {
    const [loader,setLoader] = useState(true)
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)
    const [processing ,setProcessing] =useState(false);
    const [error ,setError] =useState(false);
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [data,setData] = useState([])

    const handleClose = () => setCustomizeTable(false);

    const suhbeader = useSubheader();
    suhbeader.setTitle("Error Files History");

    useEffect(() => {
        document.title ="Error Files History | Diamonds on Call - Suppliers Center";

        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/invalid-diamond`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
        }).then((res) => {
            if (res && res.data) {
                setLoader(false)
                setData(res.data.data)
            }
        }).catch((res) => {
          if(res?.response?.data?.message === "UnAuthorized!"){
            setLoader(false)
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
        });

        axios({
            method: "GET",
            url: NODE_API_URL + "/SUPP/file-status", 
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: {"supp_id":userinfo?.id},
          }).then((res) => {
            // console.log("res",res)
            if (res && res.data ) {
            // console.log("upload_status",res.data.upload_status)
                if(res.data.upload_status === "pending") { 
                    setProcessing(true) 
                } else if(res.data.upload_status === "error") { 
                    setError(true)
                }
            }
          }).catch((res) => {
            if(res?.response?.data?.message === "UnAuthorized!"){
              setLoader(false)
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });

        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/file-upload-failed-data`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: {"supp_id":userinfo?.id},
        }).then((res) => {
            setLoader(false)
            if (res && res.data) {
                // console.log(res.data.data,"res.data.data")
                const geterrordata = res.data.data.filter(value => value.uploadStatus === "Error")
                setData(geterrordata)
            }

        }).catch((res) => {
            if(res?.response?.data?.message === "UnAuthorized!"){
              setLoader(false)
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });

        let isMounted = true;

        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":6
            };
            axios({
                method: "GET",
                url: `${NODE_API_URL}/SUPP/fetch-supp-permission`,
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                params: permiFetchData,
              }).then((res) => {
                  setLoader(false)
              //   console.log(res.data.data[0])
                  if (res && res.data) {
                      isMounted && setEditPerm(res.data.data[0].editPermission);
                      isMounted && setReadPerm(res.data.data[0].readPermission);                  }
              }).catch((res) => {
                  setLoader(false)
                  if(res?.response?.data?.message === "UnAuthorized!"){
                      localStorage.clear();
                      window.location.replace(`${BASE_URL}/logout`);
                  }
              });
          }


    },[userinfo?.supplierName,userinfo?.id,subId])


    const columns = useMemo(() => InvalidDiamondColumn(userinfo,editPerm,subId), [userinfo,editPerm,subId]);
    // const data = useMemo(() => DummyData, []);
 
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    ...columns,
                ];
            });
        }
    );
    return (
        <>{!loader ? 
            <>
            {readPerm || !subId ?
                <>
                    <div className="card-body p-0">
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <div className="font-weight-bolder font-size-h5 text-primary"> Error Files History</div>
                                <div className="d-flex" style={{width:"200px"}}>
                                    <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter}  />
                                    <div className="search_result_dropdown">
                                        <Dropdown >
                                            <Dropdown.Toggle size="sm" variant="clear">
                                                <FaEllipsisV />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Modal
                                            size="sm"
                                            show={CustomizeTable}
                                            onHide={() => setCustomizeTable(false)}
                                            aria-labelledby="Customize-table"
                                        >
                                            <Modal.Header className="px-5 py-3" closeButton>
                                                <Modal.Title id="Customize-table">
                                                    Customize Columns
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="p-2 searchResult">
                                                <PerfectScrollbar
                                                    options={perfectScrollbarOptions}
                                                    className="scroll"
                                                    style={{ maxHeight: "400px", position: "relative" }}
                                                >
                                                {/* <div  className="" > */}
                                                    <div>
                                                        <label className="checkbox my-2 border p-2">
                                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                            <span className="mr-2"></span>
                                                            Show All
                                                        </label> 
                                                    </div>
                                                    {
                                                        allColumns.map(column =>(
                                                            <div key={column.id} className={`${column.id}`}>
                                                                <label className="checkbox my-2 border p-2">
                                                                    <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                    <span className="mr-2"></span>
                                                                    {column.Header}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                {/* </div> */}
                                                </PerfectScrollbar>
                                            </Modal.Body>
                                            <Modal.Footer className="px-5 py-3">
                                                {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                                <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        {processing && 
                            <div className="mb-5 alert alert-custom alert-light-warning alert-dismissible">
                                <div className="alert-text font-weight-bold">
                                    Your Latest File is in Progress
                                </div>
                            </div>
                        }
                        {error && 
                            <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">
                                    There is some issue in your last uploaded file please try again
                                </div>
                            </div>
                        }
                        <div className="table-responsive custom-scrollbar w-100 mt-4">
                            <table className="table table-striped table-hover custom_datatable" {...getTableProps()} >
                                <thead className="custom_datatable_head">
                                    {headerGroups.map((headerGroup, i) => (
                                        <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                            {headerGroup.headers.map(
                                                (column, i) => (
                                                    <th key={i} className="text-center text-nowrap align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render("Header")}
                                                        <span className="text-secondary">
                                                            {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                        </span>
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {data.length !== 0 ? page.map((row) => {
                                        prepareRow(row)
                                        return(
                                            <React.Fragment key={row.id}>
                                                <tr {...row.getRowProps()} key={row.id}>
                                                    {row.cells.map((cell,i) => {
                                                        return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }) :
                                    <tr>
                                        <td className="text-center align-middle"colSpan="100%">
                                            No data found
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer border-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="table_page_length">
                                Page {pageIndex + 1} of {pageOptions.length}{" "}
                            </span>
                            <div>
                                <button className="btn btn-sm btn-light mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage} >
                                    <FaAngleDoubleLeft />
                                </button>
                                <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage} >
                                    <FaAngleLeft />
                                </button>
                                <span className="font-weight-bold text-muted">
                                    <input
                                        className="form-control d-inline-block form-control-sm"
                                        type="number"
                                        value={pageIndex + 1}
                                        // defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                            gotoPage(pageNumber);
                                        }}
                                        style={{ maxWidth: "70px" }}
                                    /> of {pageOptions.length} 
                                </span>
                                <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage} >
                                    <FaAngleRight />
                                </button>
                                <button className="btn btn-sm btn-light" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >
                                    <FaAngleDoubleRight />
                                </button>
                            </div>
                            <span className="d-flex align-items-center">
                                <span className="text-nowrap page_size">
                                    Rows Per page:{" "}
                                </span>
                                <select
                                    className="form-select ml-2 custom-select py-1"
                                    value={pageSize}
                                    onChange={(e) =>
                                        setPageSize(Number(e.target.value))
                                    }
                                >
                                    {[10, 25, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                    </div>
                </>
            : 
                <div className="card card-custom gutter-b">
                    <div className="card-header py-3 mb-3">
                        <div className="card-title">
                            <span className="card-label font-weight-bolder text-dark">
                                Error Files History
                            </span>
                        </div>
                    </div>
                    <div className="card-body text-center">
                        You do not have permission to access, Please contact your administrator for help
                    </div>
                </div>
            }
            </>
            : 
            <DiamFeedSk />
            // <div className="card card-custom gutter-b">
            //     <div className="card-header py-3 mb-3">
            //         <div className="card-title">
            //             <span className="card-label font-weight-bolder text-dark">
            //                 Upload History
            //             </span>
            //         </div>
            //     </div>
            //     <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
            //         Loading...<div className="spinner spinner-primary ml-5"></div>
            //     </div>
            // </div>
            }
        </>
    );
};
