import React, { useState } from 'react';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import {toAbsoluteUrl} from "../../_helpers";
import { FaExternalLinkAlt} from "react-icons/fa";
import {Modal,Button} from "react-bootstrap";
import VideoIframe from "../widgets/tiles/VideoIframe";
import { MainImagePopup } from '../widgets/tiles/MainImagePopUp';
import { round } from 'lodash';


export default function ExpandedDetailsNew({row,userinfo,SalesHistoryPage}){
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
// console.log(SalesHistoryPage)
    const [showVideo, setShowVideo] = React.useState(false);
    const [imageBroken, setImageBroken] = React.useState(false);
    const [imageBrokenAgain, setImageBrokenAgain] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const GenerateTitle = (row) => {
        let title = ""
     
        if(row?.cShape){
            title += " "
            title += row?.cShape
        }
        if(row?.cWeight){
            title += " "
            title += row?.cWeight
        }
       
        if(row?.cColor){
            title += " "
            if(row?.cColor === "fancy"){
                title += row?.fIntensity
                title += " "
                title += row?.fOvertone
                title += " "
                title += row?.fColor
            } else{
                title += row?.cColor
            }
        }
        if(row?.cClarity){
            title += " "
            title += row?.cClarity
        }

        if(row?.cCut){
            title += " "
            title += row?.cCut
        }
       
        if(row?.cPolish){
            title += " "
            title += row?.cPolish
        }

        if(row?.cSymmetry){
            title += " "
            title += row?.cSymmetry
        }

        if(row?.cFluorescence){
            title += " "
            title += row?.cFluorescence
        }
        return title
    }
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    function openVideoModal() {
        setShowVideo(true)
    }

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }


    const [mainimageclick,setMainImageClick] = useState(false)
  
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function GetRatio(row) {
        let ratioval
        if (row?.cShape != 'ROUND') {
            if (row?.cLength >= row?.cWidth) {
                ratioval = round(row?.cLength / row?.cWidth,2)
            } else if (row?.cLength < row?.cWidth) {
                ratioval = round(row?.cWidth / row?.cLength ,2)
            } else if (row?.cShape == 'HEART') {
                ratioval = round(row?.cLength / row?.cWidth ,2)
            } else {
                ratioval = '-';
            }
        } else {
            ratioval = '-';
        }
        // console.log(ratioval)
        return ratioval
    }
  
    return(
       <>
        <div className={`expandedDetails ${row?.cColor ==="fancy" || row?.supplierComment ? "fancyExpand" : "" }`} >
            <div className="row pt-2 m-0">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        <li className="img_thumb">
                            {row?.image && row?.image !== "null" ? (
                                <div>
                                    <MainImagePopup Image={row?.image} Shape={row?.cShape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                    { imageBroken ? 
                                    <>
                                        {imageBrokenAgain ?  
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                onError={() => setImageBrokenAgain(true)}
        
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${row?.cShape.toLowerCase()}-no.png`
                                                )}
                                            /> 
                                        : 
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/noimage.jpg`
                                                )}
                                            /> 
                                        }
                                    </>
                                :
                                    <img
                                        className="img-fluid cursor-pointer"
                                        alt={row?.cShape}
                                        src={row?.image}
                                        onClick={() => setMainImageClick(true)}
                                        onError={() => setImageBroken(true)}

                                    />
                                }
                                </div>
                            ) : (
                                <div>
                                    <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row?.cShape.toLowerCase()}-no.png` )} Shape={row?.cShape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                    <img
                                        className="img thumb"
                                        alt="shape"
                                        src={toAbsoluteUrl( `/media/shape/${row?.cShape.toLowerCase()}-no.png` )}
                                    />
                                </div>
                            )}
                        </li>
                        {row?.video  ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                            </li>
                        ):('')}
                        {row?.heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-5.png"/>
                            </li>
                            
                        ):('')}
                        {row?.arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-6.png"/>
                            </li>
                        ):('')}
                        {row?.asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-3.png"/>
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4">
                        {row?.image && row?.image !== "null" ? (
                            <div>
                                <MainImagePopup Image={row?.image} Shape={row?.cShape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                { imageBroken ? 
                                    <>
                                        {imageBrokenAgain ?  
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                onError={() => setImageBrokenAgain(true)}
        
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${row?.cShape.toLowerCase()}-no.png`
                                                )}
                                            /> 
                                        : 
                                            <img
                                                className="img-fluid"
                                                alt="shape"
                                                src={toAbsoluteUrl(
                                                    `/media/shape/noimage.jpg`
                                                )}
                                            /> 
                                        }
                                    </>
                                :
                                    <img
                                        className="img-fluid cursor-pointer"
                                        alt={row?.cShape}
                                        src={row?.image}
                                        onClick={() => setMainImageClick(true)}
                                        onError={() => setImageBroken(true)}

                                    />
                                }
                            </div>
                        ) : (
                            <div>
                                <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row?.cShape.toLowerCase()}-no.png` )} Shape={row?.cShape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                <img
                                    className="img-fluid"
                                    alt="shape"
                                    src={toAbsoluteUrl(
                                        `/media/shape/${row?.cShape.toLowerCase()}-no.png`
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                            <div className="mr-2"> 
                                {row?.country ? (
                                    <OverlayTrigger
                                        placement="top"
                                        className="text-capitalize"
                                        overlay={<Tooltip id="tooltip">{row?.country}</Tooltip>}
                                    >
                                        <img
                                            className="img-fluid flagIco"
                                            alt={row?.country}
                                            src={toAbsoluteUrl(`/media/map/${row?.country.toLowerCase()}.png`)}
                                        />
                                    </OverlayTrigger>
                                ) : (
                                    null
                                )}
                            </div >
                            
                            <div className="mr-2 border-right text-primary">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        row?.lab === "IGI"
                                            ? `https://www.igi.org/viewpdf.php?r=${row?.certiNo}`
                                            : row?.lab === "GIA"
                                            ? `https://www.gia.edu/report-check?reportno=${row?.certiNo}`
                                            : row?.lab === "HRD"
                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row?.certiNo}`
                                            : row?.lab === "GCAL"
                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row?.certiNo}`
                                            : row?.Certi_link
                                    }
                                >
                                    {row?.lab} : {row?.certiNo} <FaExternalLinkAlt />
                                </a>
                            </div>
                            <div >Stone ID: <div className="text-primary d-inline-block mr-2">{row?.loatNO}</div></div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h4 font-weight-bold text-capitalize">{GenerateTitle(row)}</span>
                        </div>
                        <div className="col-12 mt-5">
                            <span className="text-muted font-weight-bold font-size-sm">$/CT : ${SalesHistoryPage ? parseFloat(row?.aPrice / row?.cWeight).toFixed(2) : parseFloat(row?.acRate).toFixed(2)}</span>
                            <div className='d-flex align-items-center' ><span className="font-size-h2 font-weight-bold mb-0 d-block">${parseFloat(row?.aPrice).toFixed(2) }</span> <span className={`${row?.aDiscount >= 0  ? 'text-success' : 'text-danger' } pl-2`}> {row?.aDiscount ? parseFloat(row?.aDiscount).toFixed(2) : "0.00"}</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row?.cColor ==="fancy") ? (
                                <>
                                    <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 font-weight-bold">  {row?.fIntensity ? row?.fIntensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 font-weight-bold">  {row?.fOvertone ? row?.fOvertone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 font-weight-bold">  {row?.fColor ? row?.fColor : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Parameter Details </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">Measurement</div>
                                <div className="col-6 font-weight-bold"> {row?.cLength ? parseFloat(row?.cLength).toFixed(2) : "0.00"} * {row?.cWidth ? parseFloat(row?.cWidth).toFixed(2) : "0.00"} * {row?.cDepth ? parseFloat(row?.cDepth).toFixed(2) : "0.00"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 ">Table (%) </div>
                                <div className="col-6 font-weight-bold"> {row?.cTableP ? parseFloat(row?.cTableP).toFixed(2) : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Depth (%)</div>
                                <div className="col-6 font-weight-bold"> {row?.cDefthP ? parseFloat(row?.cDefthP).toFixed(2) : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">CR.Height</div>
                                <div className="col-6 font-weight-bold">{row?.crnHt ? row?.crnHt : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">CR.Angle</div>
                                <div className="col-6 font-weight-bold">{row?.crnAg ? row?.crnAg : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">PAV.Height</div>
                                <div className="col-6 font-weight-bold">{row?.pavDp ? row?.pavDp : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">PAV.Angle</div>
                                <div className="col-6 font-weight-bold">{row?.pavAg ? row?.pavAg : "-"}</div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">Ratio</div>
                                <div className="col-6 font-weight-bold"> {GetRatio(row)}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Girdle %</div>
                                <div className="col-6 font-weight-bold">{row?.gridlePer ? row?.gridlePer : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Girdle Condition</div>
                                <div className="col-6 font-weight-bold">{row?.gridle  ? row?.gridle : "-"}</div>
                            </div>
                        </div>
                        <div className="col-6 border-left">
                            <h5 className="font-size-h5 text-dark font-weight-bolder mb-3  mt-5">Additional Details</h5>
                            <div className="row mb-1">
                                <div className="col-6">City</div>
                                <div className="col-6 font-weight-bold">{row?.city ? row?.city : "-"}</div>
                            </div>
                            { userinfo.type != "Natural" ? "" :
                                <div className="row mb-1">
                                    <div className="col-6">Brand</div>
                                    <div className="col-6 font-weight-bold">{row?.canadaMark ? row?.canadaMark : "-"}</div>
                                </div>
                            }
                            <div className="row mb-1">
                                <div className="col-6">Shade</div>
                                <div className="col-6 font-weight-bold">{row?.shade  ? row?.shade : "-"}</div>
                            </div>
                            <hr className="w-75 ml-0"/>
                            <div className="row mb-1">
                                <div className="col-6">Milky</div>
                                <div className="col-6 font-weight-bold">{row?.milky  ? row?.milky : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Eye Clean</div>
                                <div className="col-6 font-weight-bold">{row?.eyeC ? row?.eyeC : "-"}</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Culet</div>
                                <div className="col-6 font-weight-bold"> {row?.cutlet  ? row?.cutlet : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Key to Symbol</div>
                                <div className="col-6 font-weight-bold">{row?.keySymbols ? row?.keySymbols : "-" }</div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">Availability </div>
                                <div className="col-6 font-weight-bold"> {row?.availability ? row?.availability : "-"}</div>
                            </div>
                            {userinfo.type != "Natural" ? 
                                <>
                                    <div className="row mb-1">
                                        <div className="col-6"> Growth Type </div>
                                        <div className="col-6 font-weight-bold">  {row?.brown ? row?.brown : "-"} </div>
                                    </div>
                                    {row?.green ?
                                        <div className="row mb-1">
                                            <div className="col-6"> Treatment </div>
                                            <div className="col-6 font-weight-bold">  {row?.green ? row?.green : "-"} </div>
                                        </div>
                                    : "" }
                                </>
                            :   
                                <>
                                    <div className="row mb-1">
                                        <div className="col-6"> Origin </div>
                                        <div className="col-6 font-weight-bold">  {row?.brown ? row?.brown : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6"> Treatment </div>
                                        <div className="col-6 font-weight-bold">  {row?.green ? row?.green : "-"} </div>
                                    </div>
                                </>
                            }
                            { row?.supplierComment ?
                                <div className="row mx-0 mt-5">
                                    <div className="col-12 pt-3 px-0 border-top">
                                        <p className="font-size-h4 font-weight-bold text-primary mb-3">Supplier Comment</p>
                                        <div className="row mb-1">
                                    <div className="col-12">{row?.supplierComment}</div>
                                </div>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            show={showHeartImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                <img src={row?.heart} alt={row?.cShape} className="img-fluid" />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showArrowImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                <img src={row?.arrow} alt={row?.cShape} className="img-fluid" />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showAssetImg}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Body className="text-center">
                <img src={row?.asset} alt={row?.cShape} className="img-fluid" />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                >
                Close
                </Button>
            </Modal.Footer>
        </Modal>
        <VideoIframe
            showVideo={showVideo}
            handleClose={handleClose}
            src={row?.video }
        />
      </>
    )
}