import React,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../env_config";

export const PendingApiReject = (props) => {
    const [loading, setLoading] = useState(false);
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onClose();
    };
    const RejectApifn = (props) => {
        // alert(props)
        setLoading(true);
        const finalData = {
            status: 2,
            email_Status: 1,
            session_id: props.userinfo.id,
            sr_id: props.data.srId,
            user_id: props.data.userId,
            api_id: props.data.apiId,
        };
        axios({
            method: "PATCH",
            url: `${NODE_API_URL}/SUPP/api-req-apr-or-rej`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: finalData,
        })
        .then((res) => {
            setLoading(false);
            if (res && res.data) {
                props.onHide();
                props.setTemp(!props.temp)
                props.setErrorMsg(true)
            }
        })
        .catch((error) => {
            setLoading(false);
            if(error?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
            }
        });
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className="text-center my-6">
                    <h2 className="mb-5 mt-5 text-center">Reject API</h2>
                    <div className="screenAlert-icon screenAlert-error animate">
                        <span className="screenAlert-x-mark">
                            <span className="screenAlert-line screenAlert-left animateXLeft"></span>
                            <span className="screenAlert-line screenAlert-right animateXRight"></span>
                        </span>
                        <div className="screenAlert-placeholder"></div>
                        <div className="screenAlert-fix"></div>
                    </div>
                    <p className="font-size-h4 font-weight-bold my-10">Are you sure you want to reject api?</p>
                   {props.onHide ? <Button
                        variant="secondary"
                        onClick={() => hideModel(props)}
                        className="mr-4 btn-square px-10"
                    >
                        cancel
                    </Button> : ""}
                    <Button className="btn-square px-10"
                        variant="danger"
                        onClick={() => {RejectApifn(props)}}
                    >
                        Yes
                        {loading && (
                            <span className="ml-3 spinner spinner-white"></span>
                        )}
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};
