import React, { useMemo, useEffect,useState } from "react";
import { useSubheader } from "../../../layout";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
// import DummyData from "../dummyData/uploadhistory.json";
import { InvalidDiamondColumn } from "./Columns";
import { GlobleFilter } from "../../tableFilter";
import { Dropdown, Modal } from 'react-bootstrap';
import { FaSortDown,FaSortUp,FaAngleLeft,FaAngleRight,FaAngleDoubleLeft,FaAngleDoubleRight,FaEllipsisV} from "react-icons/fa";
import Excel from "exceljs";
import axios from 'axios';
import FileSaver from "file-saver"
import { BASE_URL, NODE_API_URL } from '../../../../env_config';
import UploadHistorySkeleton from "../../SkeletonLoaders/UploadHistory";

import PerfectScrollbar from "react-perfect-scrollbar";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const SuccessHistory = ({userinfo,subId}) => {
    const [loader,setLoader] = useState(true)
    const [invData, setInvData]=useState([])
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)
    const [processing ,setProcessing] =useState(false);
    const [error ,setError] =useState(false);
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [data,setData] = useState([])

    const handleClose = () => setCustomizeTable(false);

    const suhbeader = useSubheader();
    suhbeader.setTitle("Success History");

    useEffect(() => {
        document.title ="Upload History | Diamonds on Call - Suppliers Center";

        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/invalid-diamond`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
        }).then((res) => {
            if (res && res.data) {
                setLoader(false)
                setInvData(res.data.data)
            }
        }).catch((res) => {
          if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
        });

        axios({
            method: "GET",
            url: NODE_API_URL + "/SUPP/file-status", 
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: {"supp_id":userinfo?.id},
          }).then((res) => {
            // console.log("res",res)
            if (res && res.data ) {
            // console.log("upload_status",res.data.upload_status)
                if(res.data.upload_status === "pending") { 
                    setProcessing(true) 
                } else if(res.data.upload_status === "error") { 
                    setError(true)
                }
            }
          }).catch((res) => {
            if(res?.response?.data?.message === "UnAuthorized!"){
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });

        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/upload-history`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
        }).then((res) => {
            setLoader(false)
            if (res && res.data) {
                setData(res.data.data)
            }

        }).catch((res) => {
            setLoader(false)
            if(res?.response?.data?.message === "UnAuthorized!"){
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });
        let isMounted = true;

        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":6
            };
            axios({
                method: "GET",
                url: `${NODE_API_URL}/SUPP/fetch-supp-permission`,
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                params: permiFetchData,
              }).then((res) => {
                  setLoader(false)
              //   console.log(res.data.data[0])
                  if (res && res.data) {
                      isMounted && setEditPerm(res.data.data[0].editPermission);
                      isMounted && setReadPerm(res.data.data[0].readPermission);                  }
              }).catch((res) => {
                  setLoader(false)
                  if(res?.response?.data?.message === "UnAuthorized!"){
                      localStorage.clear();
                      window.location.replace(`${BASE_URL}/logout`);
                  }
              });
          }


    },[userinfo.supplierName,userinfo.id,subId])

    const downloadExcel = (props) =>{
        // setTemp(!temp)
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("My Sheet");

        worksheet.columns = [
            {header: 'Sr. No.', key: 'Sr. No.', width: 15},
            {header: 'Stock #', key: 'Stock #', width: 15},
            {header: 'Reason', key: 'Reason', width: 15}, 
            {header: 'Shape', key: 'Shape', width: 15},
            {header: 'Weight', key: 'Weight', width: 15},
            {header: 'Color', key: 'Color', width: 15}, 
            {header: 'Clarity', key: 'Clarity', width: 15},
            {header: 'Cut', key: 'Cut', width: 15},
            {header: 'Polish', key: 'Polish', width: 15}, 
            {header: 'Symmetry', key: 'Symmetry', width: 15},
            {header: 'Fluorescence', key: 'Fluorescence', width: 15},
            {header: 'Measurements', key: 'Measurements', width: 15},
            {header: 'Lab', key: 'Lab', width: 15},
            {header: 'Certificate #', key: 'Certificate #', width: 15}, 
            {header: '$/ct', key: '$/ct', width: 15},
            {header: 'Fancy Color', key: 'Fancy Color', width: 15},
            {header: 'Fancy Color Intensity', key: 'Fancy Color Intensity', width: 15}, 
            {header: 'Fancy Color Overtone', key: 'Fancy Color Overtone', width: 15},
            {header: 'Depth %', key: 'Depth %', width: 15},
            {header: 'Table %', key: 'Table %', width: 15}, 
            {header: 'Girdle Thin', key: 'Girdle Thin', width: 15},
            {header: 'Girdle Thick', key: 'Girdle Thick', width: 15},
            {header: 'Girdle %', key: 'Girdle %', width: 15},
            {header: 'Girdle Condition', key: 'Girdle Condition', width: 15},
            {header: 'Culet Condition', key: 'Culet Condition', width: 15}, 
            {header: 'Crown Height', key: 'Crown Height', width: 15},
            {header: 'Crown Angle', key: 'Crown Angle', width: 15},
            {header: 'Pavilion Depth', key: 'Pavilion Depth', width: 15}, 
            {header: 'Pavilion Angle', key: 'Pavilion Angle', width: 15},
            {header: 'Country', key: 'Country', width: 15},
            {header: 'DiamondImage', key: 'DiamondImage', width: 15},
            {header: 'Video', key: 'Video', width: 15},
            {header: 'Heart image', key: 'Heart image', width: 15},
            {header: 'Arrow Image', key: 'Arrow Image', width: 15},
            {header: 'Aset Image', key: 'Aset Image', width: 15},
            {header: 'Key to symbols', key: 'Key to symbols', width: 15},
            {header: 'Report Comments', key: 'Report Comments', width: 15},
            {header: 'Brown', key: 'Brown', width: 15},
            {header: 'Green', key: 'Country', width: 15},
            {header: 'Milky', key: 'Milky', width: 15},
            {header: 'Eye Clean', key: 'Eye Clean', width: 15},
            {header: 'Brand', key: 'Brand', width: 15},
        ]
        worksheet.getRow(1).height = 26.4
        worksheet.getRow(1).font = {color: {argb: "FFFFFF"}};

        worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{ argb:'187DE4' }
        }

        // console.log("invData", props)
        if (props){
            props.map((value,i) => {

                let measurement = ""
                if(value?.cLength){
                    measurement += parseFloat(value?.cLength).toFixed(2)
                }
                if(value?.cWidth){
                    measurement += `*${parseFloat(value?.cWidth).toFixed(2)}`
                }
                if(value?.cDepth){
                    measurement += `*${parseFloat(value?.cDepth).toFixed(2)}`
                }
                
                let ExcelObj = {
                    'Sr. No.':i + 1,
                    'Stock #':value?.loatNO ? value?.loatNO : "",
                    'Reason':value?.reason ? value?.reason : "", 
                    'Shape':value?.cShape ? value?.cShape : "",
                    'Weight':value?.cWeight ? value?.cWeight : "",
                    'Color':value?.cColor ? value?.cColor : "", 
                    'Clarity':value?.cClarity ? value?.cClarity : "",
                    'Cut':value?.cCut ? value?.cCut : "",
                    'Polish':value?.cPolish ? value?.cPolish : "", 
                    'Symmetry':value?.cSymmetry ? value?.cSymmetry : "",
                    'Fluorescence':value?.cFluorescence ? value?.cFluorescence : "",
                    'Measurements':measurement ? measurement : "",
                    'Lab':value?.lab ? value?.lab : "",
                    'Certificate #':value?.lab === "IGI"
                                    ? `https://www.igi.org/viewpdf.php?r=${value?.certiNO}`
                                    : value?.lab === "GIA"
                                    ? `https://www.gia.edu/report-check?reportno=${value?.certiNO}`
                                    : value?.lab === "HRD"
                                    ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${value?.certiNO}`
                                    : value?.lab === "GCAL"
                                    ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${value?.certiNO}`
                                    : value?.Certificate_link, 
                    '$/ct':value?.oRate ? value?.oRate : "",
                    'Fancy Color':value?.fColor ? value?.fColor : "",
                    'Fancy Color Intensity':value?.fIntensity ? value?.fIntensity : "", 
                    'Fancy Color Overtone':value?.fOvertone ? value?.fOvertone : "",
                    'Depth %':value?.cDefthP ? value?.cDefthP : "",
                    'Table %':value?.cTableP ? value?.cTableP : "", 
                    'Girdle Thin':value?.girdleThin ? value?.girdleThin : "",
                    'Girdle Thick':value?.girdleThick ? value?.girdleThick : "",
                    'Girdle %':value?.gridlePer ? value?.gridlePer : "",
                    'Girdle Condition':value?.gridle ? value?.gridle : "",
                    'Culet Condition':value?.culetCondition ? value?.culetCondition : "", 
                    'Crown Height':value?.crnHt ? value?.crnHt : "",
                    'Crown Angle':value?.crnAg ? value?.crnAg : "",
                    'Pavilion Depth':value?.pavDp ? value?.pavDp : "", 
                    'Pavilion Angle':value?.pavAg ? value?.pavAg : "",
                    'Country':value?.city ? value?.city : "",
                    'DiamondImage':value?.image ? value?.image : "",
                    'Video':value?.video ? value?.video : "",
                    'Heart image':value?.heart ? value?.heart : "",
                    'Arrow Image':value?.arrow ? value?.arrow : "",
                    'Aset Image':value?.asset ? value?.asset : "",
                    'Key to symbols':value?.keySymbols ? value?.keySymbols : "",
                    'Report Comments':value?.reportComments ? value?.reportComments : "",
                    'Brown':value?.brown ? value?.brown : "",
                    'Green':value?.green ? value?.green : "",
                    'Milky':value?.milky ? value?.milky : "",
                    'Eye Clean':value?.eyeC ? value?.eyeC : "",
                    'Brand':value?.canadaMark ? value?.canadaMark : "",
                    
                }
                // console.log("index", i+1)
                worksheet.addRow(ExcelObj)
            })
            
            let date = new Date() //10 May 2019, 3:30:20 PM
            date = new Date(date.getTime())
            
            let dateStr = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }) // 10/05/19

            let arr = dateStr.split("/")// [ '10', '05', '19' ]
            let d = arr[0]; //e.g. 10 
            let m = arr[1]; //e.g. 5 
            let y = arr[2]; //e.g. 19

            let timeStr = date.toLocaleTimeString("en-GB", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" }) //
            let arr2 = timeStr.split(":") // 15:30:20
            let H = arr2[0]; //e.g. 15
            let i = arr2[1]; //e.g. 30
            let s = arr2[2]; //e.g. 20
            worksheet.eachRow(function (Row, rowNum) {
                /** Row.alignment not work */
                // Row.alignment = { horizontal: 'left' }
        
                Row.eachCell(function (Cell, cellNum) {
                    /** cell.alignment not work */
                    if (rowNum == 1) {
                        Cell.alignment = {
                            vertical: 'middle',
                            horizontal: 'center'
                        }
                    }else{
                        Cell.alignment = {
                            vertical: 'middle',
                            horizontal: 'center'
                        }
                    }
                })
            })
        
            workbook.xlsx.writeBuffer()
                .then(buffer => FileSaver.saveAs(new Blob([buffer]), `Invalid-Stock${y}-${m}-${d}-${H}-${i}-${s}.xlsx`))
                .catch(err => {})
        }
        
    }
    const columns = useMemo(() => InvalidDiamondColumn(userinfo,editPerm,subId,downloadExcel,invData), [userinfo,editPerm,subId,invData]);
   
    const globalFilterFunction = (rows, columnIds, filterValue) => {
        return rows.filter(row => {
            const values = Object.values(row.original); // Get all field values of the row
            return values.some(value => 
                String(value).toLowerCase().includes(filterValue?.toLowerCase())
            );
        });
    };
   
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            globalFilter: globalFilterFunction
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    ...columns,
                ];
            });
        }
    );
    return (
        <>{!loader ? 
            <>
            {readPerm || !subId ?
                    <>
                        <div className={`card-body ${userinfo.uploadMode === "File" ? "p-0" : ""}`}>
                            <div className="w-100 d-flex justify-content-between align-items-center">
                                {userinfo.uploadMode === "File" ? 
                                    <div>
                                        <div className="font-weight-bolder font-size-h5 text-primary"> Upload History</div>
                                        <div>Keep track of files uploaded into insights and review the result after system processes your file.</div>
                                    </div> 
                                : 
                                    <div> Keep track of files uploaded into insights and review the result after system processes your file.</div> 
                                }
                                <div className="d-flex" style={{width:"200px"}}>
                                    <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter}  />
                                    <div className="search_result_dropdown">
                                        <Dropdown >
                                            <Dropdown.Toggle size="sm" variant="clear">
                                                <FaEllipsisV />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Modal
                                            size="sm"
                                            show={CustomizeTable}
                                            onHide={() => setCustomizeTable(false)}
                                            aria-labelledby="Customize-table"
                                        >
                                            <Modal.Header className="px-5 py-3" closeButton>
                                                <Modal.Title id="Customize-table">
                                                    Customize Columns
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="p-2 searchResult">
                                                <PerfectScrollbar
                                                    options={perfectScrollbarOptions}
                                                    className="scroll"
                                                    style={{ maxHeight: "400px", position: "relative" }}
                                                >
                                                {/* <div  className="" > */}
                                                    <div>
                                                        <label className="checkbox my-2 border p-2">
                                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                            <span className="mr-2"></span>
                                                            Show All
                                                        </label> 
                                                    </div>
                                                    {
                                                        allColumns.map(column =>(
                                                            <div key={column.id} className={`${column.id}`}>
                                                                <label className="checkbox my-2 border p-2">
                                                                    <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                    <span className="mr-2"></span>
                                                                    {column.Header}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                {/* </div> */}
                                                </PerfectScrollbar>
                                            </Modal.Body>
                                            <Modal.Footer className="px-5 py-3">
                                                {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                                <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                            {processing && 
                                <div className="mb-5 alert alert-custom alert-light-warning alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                        Your Latest File is in Progress
                                    </div>
                                </div>
                            }
                            {error && 
                                <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                        There is some issue in your last uploaded file please try again
                                    </div>
                                </div>
                            }
                            <div className="table-responsive custom-scrollbar w-100 mt-4">
                                <table className="table table-striped table-hover custom_datatable" {...getTableProps()} >
                                    <thead className="custom_datatable_head">
                                        {headerGroups.map((headerGroup, i) => (
                                            <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                                {headerGroup.headers.map(
                                                    (column, i) => (
                                                        <th key={i} className="text-center  text-nowrap align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render("Header")}
                                                            <span className="text-secondary">
                                                                {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                            </span>
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {data.length !== 0 ? page.map((row) => {
                                            prepareRow(row)
                                            return(
                                                <React.Fragment key={row.id}>
                                                    <tr {...row.getRowProps()} key={row.id}>
                                                        {row.cells.map((cell,i) => {
                                                            return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                        })}
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }) :
                                        <tr>
                                            <td className="text-center align-middle"colSpan="100%">
                                                No data found
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer border-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="table_page_length">
                                    Page {pageIndex + 1} of {pageOptions.length}{" "}
                                </span>
                                <div>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage} >
                                        <FaAngleDoubleLeft />
                                    </button>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage} >
                                        <FaAngleLeft />
                                    </button>
                                    <span className="font-weight-bold text-muted">
                                        <input
                                            className="form-control d-inline-block form-control-sm"
                                            type="number"
                                            value={pageIndex + 1}
                                            // defaultValue={pageIndex + 1}
                                            onChange={(e) => {
                                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                                gotoPage(pageNumber);
                                            }}
                                            style={{ maxWidth: "70px" }}
                                        /> of {pageOptions.length} 
                                    </span>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage} >
                                        <FaAngleRight />
                                    </button>
                                    <button className="btn btn-sm btn-light" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >
                                        <FaAngleDoubleRight />
                                    </button>
                                </div>
                                <span className="d-flex align-items-center">
                                    <span className="text-nowrap page_size">
                                        Rows Per page:{" "}
                                    </span>
                                    <select
                                        className="form-select ml-2 custom-select py-1"
                                        value={pageSize}
                                        onChange={(e) =>
                                            setPageSize(Number(e.target.value))
                                        }
                                    >
                                        {[10, 25, 50].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </span>
                            </div>
                        </div>
                    </>
            : 
                <div className="card card-custom gutter-b">
                    <div className="card-header py-3 mb-3">
                        <div className="card-title">
                            <span className="card-label font-weight-bolder text-dark">
                                Upload History
                            </span>
                        </div>
                    </div>
                    <div className="card-body text-center">
                        You do not have permission to access, Please contact your administrator for help
                    </div>
                </div>
            }
            </>
            : 
            <UploadHistorySkeleton />
            // <div className="card card-custom gutter-b">
            //     <div className="card-header py-3 mb-3">
            //         <div className="card-title">
            //             <span className="card-label font-weight-bolder text-dark">
            //                 Upload History
            //             </span>
            //         </div>
            //     </div>
            //     <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
            //         Loading...<div className="spinner spinner-primary ml-5"></div>
            //     </div>
            // </div>
            }
        </>
    );
};
