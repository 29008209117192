import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

export function Topbar(props) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      // viewNotificationsDisplay: objectPath.get( uiService.config, "extras.notifications.display" ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}
      <div className="align-self-center mr-3">Account Status:
      {props.userinfo.isPending === 0 ?
        <>
          {props.userinfo.status === 0  ? 
            <>
              {
                props.userinfo.stockStatus === 1 ?
                  <span className="label label-inline label-danger ml-2 label-md font-weight-bold"> Freeze </span>
                : <span className="label label-inline label-success ml-2 label-md font-weight-bold"> Active </span>
              }
            </> 
          : <span className="label label-inline label-danger ml-2 label-md font-weight-bold"> InActive </span>
          }
        </>
        : <span className="label label-inline label-warning ml-2 label-md font-weight-bold"> Pending </span>
      }
        {/* <span className="label label-inline label-danger ml-2 label-md font-weight-bold"> freeze </span> */}
      </div>
      {layoutProps.viewUserDisplay && <QuickUserToggler subId={props.subId} userinfo={props.userinfo} subSuppData={props.subSuppData} />}
    </div>
  );
}