import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
import { ImagePopup } from "../widgets/tiles/ImagePopUp";
import { VideoPopup } from "../widgets/tiles/VideoPopUp";
import { FaAward } from "react-icons/fa";
import { FcShipped } from "react-icons/fc";

function total(x) {
    let total = parseFloat(x.oRate * x.cWeight).toFixed(2);
    return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function discount(x){
        
    let orignal_price = parseFloat(x.oRate * x.cWeight).toFixed(2);
    let carat_price = parseFloat(orignal_price / x.cWeight).toFixed(2);
    let discount = x.rapRate ? parseFloat((carat_price - x.rapRate) / (x.rapRate) * 100).toFixed(2) : "0.00" 
    return parseFloat(discount).toFixed(2);
}


export const searchResultColumn = () => [
    
    {
        Header: "Availability",
        accessor: "availability",
        className: "text-center align-middle",
        Cell: (row) => (
            <>
                {row.row.original.availability === "Guaranteed" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Guaranteed</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-success" >G</span>
                    </OverlayTrigger>
                : row.row.original.availability === "Memo" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Memo</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-primary" >M</span>
                    </OverlayTrigger>
                : row.row.original.availability === "Hold" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Hold</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-primary" >H</span>
                    </OverlayTrigger> 
                : row.row.original.availability === "Not Available" 
                ?   <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Not Available</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-danger" >NA</span>
                    </OverlayTrigger>  
                : row.row.original.availability === "Subject to Prior sale" 
                ?   <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Subject to Prior sale</Tooltip>}
                    >
                        <span class="font-weight-bolder label label-warning" >S</span>
                    </OverlayTrigger>
                : "-"}
            </>
        ),
    },
    {
        Header: "Stone ID",
        accessor: "loatNo",
        className: "text-center align-middle",
        Cell: (row) => (
            <>
                {row.row.original.custTypeNo === "0" 
                ?  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">Activated Ship Stone</Tooltip>}
                    >
                       <span className="text-primary font-weight-bold"><FcShipped className="mr-2 font-size-h4"/>{row.value}</span>
                    </OverlayTrigger>
                : row.value
                }
                
            </>
        ),
    },
    {
        Header: "Country" ,
        accessor: "country",
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    {row.value ? (
                        <OverlayTrigger
                            placement="top"
                            className="text-capitalize"
                            overlay={
                                <Tooltip id="tooltip">{row.value}</Tooltip>
                            }
                        >
                            <img
                                className="img-fluid flagIco"
                                alt={row.value}
                                src={toAbsoluteUrl(
                                    `/media/map/${row.value.toLowerCase()}.png`
                                )}
                            />
                        </OverlayTrigger>
                    ) : null}
                </>
            );
        },
    },
    {
        Header: "Media" ,
        accessor: (d) => (
            <>
                <div className="d-flex justify-content-center align-items-center">
                    {d.image ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Image</Tooltip>}
                            >
                                <ImagePopup
                                    Image={d.image}
                                    Shape={d.cShape}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.image}><BsCardImage/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block"  style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.video ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Video</Tooltip>}
                            >
                                <VideoPopup
                                    ProductId={d.id}
                                    WLDiamondType={d.diamondType}
                                    Certi_No={d.certiNo}
                                    Video={d.video}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.video}><BsFillCameraVideoFill/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block" style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.certiNo ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">Certificate</Tooltip>
                                }
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        d.lab === "IGI"
                                            ? `https://www.igi.org/viewpdf.php?r=${d.certiNo}`
                                            : d.lab === "GIA"
                                            ? `https://www.gia.edu/report-check?reportno=${d.certiNo}`
                                            : d.lab === "HRD"
                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.certiNo}`
                                            : d.lab === "GCAL"
                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.certiNo}`
                                            : d.Certi_link
                                    }
                                >
                                    <FaAward />
                                </a>
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span className="d-inline-block" style={{ width: "27px", height:"27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: "Shape",
        accessor: "cShape",
        className: "text-center align-middle p-2",
        sortMethod: (a, b) => a.localeCompare(b),
        Cell: (row) => (
            <>
                <SVG
                    width="22px"
                    height="22px"
                    src={toAbsoluteUrl(
                        `/media/shape/svg_shape/${row.row.original.cShape.toLowerCase()}.svg`
                    )}
                />
                <div className="small">{row.row.original.cShape}</div>
            </>
        ),
    },
    {
        Header: "Carat",
        accessor: "cWeight",
        // sortMethod: (a, b) => Number(a) - Number(b),
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{parseFloat(row.value).toFixed(2)}</>;
        },
        sortType: (rowA, rowB) => {
            if (rowB.original.cWeight > rowA.original.cWeight) return 1;
            if (rowA.original.cWeight > rowB.original.cWeight) return -1;
        }
    },
    {
        Header: "Color",
        accessor: "cColor",
        minwidth: 50,
        className: "text-center align-middle",
        sortMethod: (a, b) => a.localeCompare(b),
        Cell: (row) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {row.row.original.cColor === "fancy" ? (
                                <>
                                    {row.row.original.fIntensity}{" "}{row.row.original.fOvertone}{" "}{row.row.original.fColor}
                                </>
                            ) : (
                                row.row.original.cColor
                            )}
                        </Tooltip>
                    }
                >
                    <span
                        className="d-inline-block text-truncate text-dark"
                        style={{ maxWidth: "80px" }}
                    >
                        {row.row.original.cColor === "fancy" ? (
                            <>
                                {row.row.original.fIntensity}{" "}{row.row.original.fOvertone}{" "}{row.row.original.fColor}{" "}
                            </>
                        ) : (
                            row.row.original.cColor
                        )}
                    </span>
                </OverlayTrigger>
            </>
        ),
    },
    {
        Header: "Clarity",
        accessor: "cClarity",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Cut",
        accessor: "cCut",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Polish",
        accessor: "cPolish",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Symmetry",
        accessor: "cSymmetry",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Fluo.",
        accessor: "cFluorescence",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Lab",
        accessor: "lab",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: "Certificate",
        accessor: "certiNo",
        className: "text-center align-middle",
        Cell: (row) => {
            return (
                <>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary mx-2 text-left"
                        href={
                            row.row.original.Lab === "IGI"
                                ? `https://www.igi.org/viewpdf.php?r=${row.row.original.certiNo}`
                                : row.row.original.Lab === "GIA"
                                ? `https://www.gia.edu/report-check?reportno=${row.row.original.certiNo}`
                                : row.row.original.Lab === "HRD"
                                ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.row.original.certiNo}`
                                : row.row.original.Lab === "GCAL"
                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.row.original.certiNo}`
                                : row.row.original.Certi_link
                        }
                    >
                        {row.row.original.certiNo}
                    </a>
                </>
            )
        },
        
    },
    {
        Header: "disc %",
        className: "text-right align-middle pr-5",
        accessor: (row) => (
            <>
               <span className={`${discount(row) >= 0  ? 'text-success' : 'text-danger'} pl-2`}> {discount(row)}</span>
            </>
        ),
        disableSortBy: true,
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    {
        Header: "$/CT",
        className: "text-right align-middle pr-5",
        accessor: "oRate",
        sortMethod: (a, b) => Number(a) - Number(b),
        Cell: (row) => (
            <>
                {
                    <span> ${parseFloat(row.value).toFixed(2) }</span>
                }
            </>
        ),
    },
    {
        Header: "Total",
        className: "text-right align-middle pr-5",
        accessor: (d) => {
            return (
                <>
                  ${total(d)}
                </>
            );
        },
        disableSortBy: true,
    }
];
