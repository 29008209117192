import React, { useState,useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  // Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
// import axios from "axios"
import PersonalInfoForm from './Forms/PersonalInfoForm';
import CompanyInfoForm from './Forms/CompanyInfoForm';
// import ShippingInfoForm from './Forms/ShippingInfoForm';
import CompanyRefForm from './Forms/CompanyRefForm';
// import ReviewOrder from './ReviewOrder';
import ProfileSuccess from './ProfileSuccess/ProfileSuccess';
// import { NODE_API_URL } from '../../../../env_config';
import validationSchema from './FormModel/validationSchema';
import kycFormModel from './FormModel/kycFormModel';
// import formInitialValues from './FormModel/formInitialValues';
import { FaChevronLeft } from 'react-icons/fa';

import useStyles from './styles';
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../../env_config';

const steps = ['User Information', 'Company Profile', 'Company Reference'];
const { formId, formField } = kycFormModel;

function _renderStepContent(step,setFirstFile,setFieldValue,setSecondFile,setThirdFile,prev,setPrev,values,FirstFile,setCompanyRegName,setSupplierLogoName,setDocTypeName,SecondFile,ThirdFile,userinfo) {
  switch (step) {
    case 0:
      // console.log(userinfo,"userinfo")
      return <PersonalInfoForm userinfo={userinfo} formField={formField} setFirstFile={setFirstFile} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev} FirstFile={FirstFile} setSupplierLogoName={setSupplierLogoName} setDocTypeName={setDocTypeName} values={values}/>;
    case 1:
      return <CompanyInfoForm setFieldValue={setFieldValue} formField={formField} setSecondFile={setSecondFile} setThirdFile={setThirdFile} values={values} setFirstFile={setFirstFile} setSupplierLogoName={setSupplierLogoName} setCompanyRegName={setCompanyRegName} setDocTypeName={setDocTypeName} FirstFile={FirstFile} SecondFile={SecondFile} ThirdFile={ThirdFile} />;
    case 2:
      // return <ShippingInfoForm formField={formField} values={values} setFieldValue={setFieldValue} />;
      return <CompanyRefForm formField={formField} values={values} setFieldValue={setFieldValue} />;
    default:
      return <div>Not Found</div>;
  }
}

export default function KycUpdate({userinfo}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [FirstFile,setFirstFile] = useState()
  const [SecondFile,setSecondFile] = useState()
  const [ThirdFile,setThirdFile] = useState()
  const [supplierLogoName,setSupplierLogoName] = useState("")
  const [companyregname,setCompanyRegName] = useState("")
  const [docTypeName,setDocTypeName] = useState("")
  // console.log(FirstFile,JSON.stringify(SecondFile),ThirdFile,"FILES")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const[initialValue,setInitialValue] =useState({})
  console.log(initialValue)
  useEffect(() => {
    axios({
      method: "GET",
      url: `${NODE_API_URL}/SUPP/profile-data`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
      },
      data: {"id":userinfo.id},
  }).then(res => {
    console.log(res.data,"res.data")
      if(res && res.data && res.data.data){
        // console.log("res",res)
        let user ={
          email:res.data?.data[0].email ? res.data?.data[0].email : "",
          firstname:res.data?.data[0].firstName ? res.data?.data[0].firstName : "",
          lastname:res.data?.data[0].lastName ? res.data?.data[0].lastName : "",
          designation:res.data?.data[0].designation ? res.data?.data[0].designation : "",
          supplier_name_o:res.data?.data[0].supplierNameO ? res.data?.data[0].supplierNameO : "",
          moblie_no:res.data?.data[0].mobileNo ? res.data?.data[0].mobileNo : "",
          supplier_logo:res.data?.data[0].supplierLogo ? res.data?.data[0].supplierLogo : "",
          address:res.data?.data[0].address ? res.data?.data[0].address : "",
          pincode:res.data?.data[0].pincode ? res.data?.data[0].pincode : "",
          city:res.data?.data[0].city ? res.data?.data[0].city : "",
          state:res.data?.data[0].state ? res.data?.data[0].state : "",
          country:res.data?.data[0].country ? res.data?.data[0].country : "",
          website:res.data?.data[0].website ? res.data?.data[0].website : "",
          cc_email:res.data?.data[0].ccEmail ? res.data?.data[0].ccEmail : "",
          telphone:res.data?.data[0].telephone ? res.data?.data[0].telephone : "",
          c_register_no:res.data?.data[0].cRegisterNo ? res.data?.data[0].cRegisterNo : "",
          c_register_upload:res.data?.data[0].cRegisterUpload ? res.data?.data[0].cRegisterUpload : "",
          c_partnet_name:res.data?.data[0].cPartnerName ? res.data?.data[0].cPartnerName : "",
          c_document_type:res.data?.data[0].cDocumentType ? res.data?.data[0].cDocumentType : "",
          c_partnet_doc:res.data?.data[0].cPartnerDoc ? res.data?.data[0].cPartnerDoc : "",
          comp_name_1:res.data.company_ref.compName1 ? res.data.company_ref.compName1 : "",
          person_name_1:res.data.company_ref.compName1 ? res.data.company_ref.compName1 : "",
          designation_1:res.data.company_ref.compName1 ? res.data.company_ref.compName1 : "",
          email_id_1:res.data.company_ref.emailId1 ? res.data.company_ref.emailId1 : "",
          contact_no_1:res.data.company_ref.contactNo1 ? res.data.company_ref.contactNo1 : "",
          comp_name_2:res.data.company_ref.compName2 ? res.data.company_ref.compName2 : "",
          person_name_2:res.data.company_ref.personName2 ? res.data.company_ref.personName2 : "",
          designation_2:res.data.company_ref.designation2 ? res.data.company_ref.designation2 : "",
          email_id_2:res.data.company_ref.emailId2 ? res.data.company_ref.emailId2 : "",
          contact_no_2:res.data.company_ref.contactNo2 ? res.data.company_ref.contactNo2 : "",
         }
         setInitialValue(user)
        }
      }).catch(err => {
        // console.log(err)
        if(err?.response?.data?.message === "UnAuthorized!"){
          localStorage.clear();
          window.location.replace(`${BASE_URL}/logout`);
        }
      })
    },[userinfo.id])
    
    // console.log("initialValue",initialValue)
  async function _submitForm(values, actions) {
    await _sleep(1000);
    const formData = new FormData();
    if(FirstFile){
      formData.append("user_file", FirstFile[0])
      
    }
    if(SecondFile){
    formData.append("user_file", SecondFile[0])

    }
    if(ThirdFile){
      formData.append("user_file", ThirdFile[0])

    }
    

    const DataObj = {
      session_id:userinfo.id,
      email:values.email,
      firstname:values.firstname,
      lastname:values.lastname,
      designation:values.designation,
      supplier_name_o:values.supplier_name_o,
      moblie_no:values.moblie_no,
      supplier_logo:values.supplier_logo,
      address:values.address,
      pincode:values.pincode,
      city:values.city,
      state:values.state,
      country:values.country,
      website:values.website,
      cc_email:values.cc_email,
      telphone:values.telphone,
      c_register_no:values.c_register_no,
      c_register_upload:values.c_register_upload,
      c_partnet_name:values.c_partnet_name,
      c_document_type:values.c_document_type,
      c_partnet_doc:values.c_partnet_doc,
      comp_name_1:values.comp_name_1,
      person_name_1:values.person_name_1,
      designation_1:values.designation_1,
      email_id_1:values.email_id_1,
      contact_no_1:values.contact_no_1,
      comp_name_2:values.comp_name_2,
      person_name_2:values.person_name_2,
      designation_2:values.designation_2,
      email_id_2:values.email_id_2,
      contact_no_2:values.contact_no_2,
    }
    for ( var key in DataObj ) {
      formData.append(key, DataObj[key]);
  }
  // console.log("DataObj",DataObj)
    axios({
      method: "POST",
      url: `${NODE_API_URL}/SUPP/profile-insert`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
      },
      data: formData,
    }).then(res => {
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
      // window.location.reload(true)

      // console.log(res)
    }).catch(err => {
      actions.setSubmitting(false);
      if(err?.response?.data?.message === "UnAuthorized!"){
        localStorage.clear();
        window.location.replace(`${BASE_URL}/logout`);
    }
      // console.log(err.resopnse)
    })
    // console.log(DataObj,"DataObj")
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
 const [prev,setPrev] = useState(false)
  function _handleBack() {
    setActiveStep(activeStep - 1);
    setPrev(true)
  }

  return (
    <div className='card'>
      {activeStep === steps.length ? (
          <></>
        ) : (
          <div className='card-header p-0 border-bottom-0'>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        )}
      <div className='card-body'>
        {activeStep === steps.length ? (
          <ProfileSuccess />
        ) : (
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting,setFieldValue, values }) => (
              <Form className='bg-white' id={formId}>
                {_renderStepContent(activeStep,setFirstFile,setFieldValue,setSecondFile,setThirdFile,prev,setPrev,values,FirstFile,setCompanyRegName,setSupplierLogoName,setDocTypeName,SecondFile,ThirdFile,userinfo)}
                <Grid className="mt-10" container justify={activeStep !== 0 ? "space-between" : "flex-end"} alignItems="center"  >
                  {activeStep !== 0 && (
                    <Button color="primary" onClick={_handleBack} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">PREVIOUS</span >
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "COMPLETE_PROFILE" : "NEXT"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}
