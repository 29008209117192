import React , { useEffect } from "react";
import {Link} from "react-router-dom";
import { Alert } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import '../../../../node_modules/react-circular-progressbar/dist/styles.css';
import { useSubheader } from "../../layout";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../env_config";
// import "./Dashboard.css"
import {
  HoldreqTile,
  ConfirmHistoryTile,
  SaleHistoryTile,
  ActiveStoneTile,
  InvalidstoneTile,
  ProfileTile,
  RecentOrderDetails,
  SalesChart,
  DashboardProfile
} from "./dashboardComponent";
import {toAbsoluteUrl} from "../../_helpers";

export function Dashboard({userinfo,subId}) {
  const [editPerm, setEditPerm] = React.useState(0)
  const [readPerm, setReadPerm] = React.useState(0)
  const [dashData, setDashData] = React.useState([]);
  const [supplierData, setSupplierData] = React.useState([]);
  const [recentOrderData, setRecentOrderData] = React.useState([]);
  const [salesChartData, setSalesChartData] = React.useState([]);

  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard");
  // console.log(userinfo)
  useEffect(() => {
      document.title = "Dashboard | Diamonds on Call - Suppliers Center";

      let isMounted = true;
        const finalData = {
          "C_Name":userinfo?.supplierName,
          "id":userinfo?.id,
          "Type":userinfo?.type === "Natural" ? "N" : "L",
          "stock_status":`${userinfo?.stockStatus}`
        };
        const recentOrderData = {
          "C_Name":userinfo?.supplierName,
        };

        if (subId){
          const permiFetchData = {
            "sub_supp_id":subId,
            "resource_id":1
          };
          axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/fetch-supp-permission`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            params: permiFetchData,
          }).then((res) => {
            // console.log(res.data.data[0])
              if (res && res.data) {
                  isMounted && setEditPerm(res.data.data[0].editPermission);
                  isMounted && setReadPerm(res.data.data[0].readPermission);              }
          }).catch((res) => {
            if(res?.response?.data?.message === "UnAuthorized!"){
              localStorage.clear();
              window.location.replace(`${BASE_URL}/logout`);
            }
          });
        }

        // console.log("data", userinfo);
        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/dash-board-data`,
            headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
            data: finalData,
        }).then((res) => {
          // console.log(res)
            if (res && res.data) {
                isMounted && setDashData(res.data);
                isMounted && setSupplierData(res.data.suppDetail);
            }
        }).catch((res) => {
          if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
        });

        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/recently-order-details`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: recentOrderData,
        }).then((res) => {
          if (res && res.data) {
                // console.log("res",res.data.data)
                  isMounted && setRecentOrderData(res.data.data);
              }
        }).catch((res) => {
          if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
        });

        axios({
          method: "GET",
          url: `${NODE_API_URL}/SUPP/sales-chart`,
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            },
          data: recentOrderData,
      }).then((res) => {
        // console.log(res.data.data)
        if (res && res.data) {
                isMounted && setSalesChartData(res.data.data);
            }
      }).catch((res) => {
          if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
        });
  }, [userinfo?.supplierName,userinfo?.id,subId,userinfo?.stockStatus,userinfo?.type]);

// console.log(readPerm)
  const percentage = dashData?.activeStone > 0 ? 60 : 30;
  return (
    <>
    <div className="row ">
          <div className="col-12">
              <h2 className="text-dark mb-4">Welcome to Supplier centre, <span className="text-primary">{userinfo?.supplierNameO}</span></h2>
          </div>
    </div>
    <div className="row">
      <div className="col-12">
      {userinfo?.status === 1 &&
        <Alert variant="danger">
           Your account is Inactive, please contact your purchase executive.
        </Alert> }
      </div>
    </div>
    {userinfo?.isPending === 1 &&
      <>
        <div className="row ">
          {/* <div className="col-12">
              <h2 className="text-dark mb-4">Welcome to Supplier centre</h2>
          </div> */}
          <div className="col-12">
            
              {(((!userinfo?.designation) || (!userinfo?.address) || !userinfo?.cPartnerDoc) || (!userinfo?.cDocumentType) || (!userinfo?.cPartnerName) || (!userinfo?.cRegisterUpload) || (!userinfo?.cRegisterNo)) ?
                <Alert variant="danger">
                  {/* Your profile is under admin review. Please wait for approval until then you can start uploading your stock */}
                    Your profile is not Activated. Please Complete your <Link to="/profile">profile</Link>.
                </Alert> 
              :
                <Alert variant="success">
                  Thank you for completing your profile. Please wait for approval, until then you can start uploading your stock.
                </Alert>
              }
          </div>
        </div> 
        <div className="row shadow-xs flex-row card card-custom gutter-b mx-0 py-10 border">
            <div className="col-lg-3 col-md-6 text-center">
              <Link to="/profile" >
                <img className="mb-3" alt="Business Info" src={toAbsoluteUrl("/media/deal.svg")}/>
                <h5 className="text-danger mt-3">Business Info</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <Link to="/upload-diamond" >
                <img alt="Add Listing" src={toAbsoluteUrl("/media/add_list.svg")}/>
                <h5 className="text-danger mt-3">Add Listing</h5>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <img alt="Get Approved" src={toAbsoluteUrl("/media/Get_appr.svg")}/>
              <h5 className="text-muted mt-3">Get Approved</h5>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mx-auto" style={{ width: 78, height: 78 }}>
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage}%`}
                  strokeWidth={15}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#007bff",
                })}/>
              </div>
              <h5 className="text-muted mt-3">Complete Your Profile</h5>
            </div>
        </div>
      </>
    }
        
      <div className="row dashboardCard">
        <div className="col-lg-4 col-md-6">
          <HoldreqTile holdRequestCount={dashData?.holdRequestStone} userinfo={userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <ConfirmHistoryTile confirmHistoryCount={dashData?.confirmHistoryStone} userinfo={userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <SaleHistoryTile salesHistoryCount={dashData?.salesHistoryStone} userinfo={userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <ActiveStoneTile activeStoneCount={dashData?.activeStone} userinfo={userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <InvalidstoneTile invalidDiamondCount={dashData?.invalidDiamond} userinfo={userinfo} />
        </div>
        <div className="col-lg-4 col-md-6">
          <ProfileTile />
        </div>
        {readPerm || !subId ? 
         <>
            <div className="col-12">
              <RecentOrderDetails recentOrderData={recentOrderData} userinfo={userinfo} />
            </div>
            <div className="col-lg-8">
              <SalesChart salesChartData={salesChartData} />
            </div>
            <div className="col-lg-4">
              <DashboardProfile supplierData={supplierData} />
            </div>
         </> : ""
        }
      </div>
    </>
  );
}
