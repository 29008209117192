import React from "react";
import { BASE_URL_OLD } from "../../../../env_config";

export const CompanyInformation = (props) => {
    return (
        <div className="card card-custom card-stretch">
            <div className="card-header py-3">
                <div className="card-title"> Personal Information </div>
            </div>
            <div className="card-body">
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Company Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.supplierNameO}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Address:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.address}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Company Website:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.website}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Company Email:
                    </div>
                    <div className="col-lg-9 col-xl-10">{props.user?.email}</div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Business Registration Number:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.cRegisterNo}
                        <a
                            className="d-inline-block ml-2"
                            href={`${BASE_URL_OLD}/assets/documents/${props.user?.cRegisterUpload}`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            View Document
                        </a>
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Director/Partner Name:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.cPartnerName}
                    </div>
                </div>
                <div className="form-group row align-items-center mb-0">
                    <div className="col-xl-2 col-lg-3 col-form-label font-weight-bold">
                        Type Of Document:
                    </div>
                    <div className="col-lg-9 col-xl-10">
                        {props.user?.cDocumentType}
                        <a
                            className="d-inline-block ml-2"
                            href={`${BASE_URL_OLD}/assets/documents/${props.user?.cPartnerDoc}`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            View Document
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
