/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
// import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
// import { HiUsers,HiUser } from 'react-icons/hi';
import { FaUserCircle,FaUserFriends } from 'react-icons/fa';



export function UserProfileDropdown(props) {
  // const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className={`symbol symbol-35 symbol-light-${props.subId ? "info" :"primary"} symbol-circle`}>
            <span className="symbol-label font-size-h5 font-weight-bold">
             {props.subId ?
              <FaUserFriends className="text-info font-size-h2" />
              :
              <FaUserCircle className="text-primary font-size-h2" />
            }
              {/* <i className="flaticon2-user text-primary"></i> */}
            </span>
            {props.userinfo?.isPending === 0 ?
              <>
                {props.userinfo?.status === 0  ? 
                  <>
                    {
                      props.userinfo?.stock_status === 1 ?
                      <i className="symbol-badge symbol-badge-bottom bg-danger"></i>
                      : <i className="symbol-badge symbol-badge-bottom bg-success"></i>
                    }
                  </> 
                : <i className="symbol-badge symbol-badge-bottom bg-danger"></i>
                }
              </>
              : <i className="symbol-badge symbol-badge-bottom bg-warning"></i>
            }
            {/* <i className="symbol-badge symbol-badge-bottom bg-success"></i> */}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {props.userinfo?.supplierName ? props.userinfo?.supplierName : ""}
                  <span className="text-white-50 small m-0 d-block">{props.userinfo?.firstName ? props.userinfo?.firstName  : ""} {props.userinfo?.lastName ? props.userinfo?.lastName : ""}</span>
                </div>

              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-5 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl( "/media/misc/bg-1.jpg" )})`,
              }}
            >
              {/* <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.firstName[0]}
                </span>
              </div> */}
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {props.subSuppData.length === 0 ?
                  <>
                    {props.userinfo?.supplierName ? props.userinfo?.supplierName : ""}
                    <span className="text-white-50 small text-capitalize m-0 d-block">{props.userinfo?.firstName ? props.userinfo?.firstName  : ""} {props.userinfo?.lastName ? props.userinfo?.lastName : ""}</span>
                  </>
                  : <>
                      <span className="text-capitalize">{props.subSuppData.userName ? props.subSuppData.userName : ""}</span>
                      <span className="text-white-50 small text-capitalize m-0 d-block">{props.subSuppData.email ? props.subSuppData.email : ""}</span>
                    </> }
              </div>
              <div className="navi-footer">
                <Link to="/logout" className="btn btn-light-primary font-weight-bold" >
                  Sign Out
                </Link>
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pb-5 pt-5">
          <Link to="/profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Profile Settings
                </div>
              </div>
            </div>
          </Link>
          <Link to="/settings" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-gear text-warning" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Settings
                </div>
                <div className="text-muted">
                Security setting and more
                </div>
              </div>
            </div>
          </Link>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
