import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../env_config";

const initialValues = {
    comments: "",
};

export const FeedCancel = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setShowPopup(props.show);
    }, [props.show]);

    const RegistrationSchema = Yup.object().shape({
        comments: Yup.string().required(
            "Please mention the reason for cancel request in the comment box to cancel"
        ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            const finalData = {
                status: 2,
                cancel_reason: values.comments,
                email_Status: 1,
                session_id: props.userinfo.id,
                sr_id: props.data.srId,
                user_id: props.data.userId,
                api_id: props.data.apiId,
            };
            axios({
                method: "PATCH",
                url: `${NODE_API_URL}/SUPP/api-req-apr-or-rej`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                  },
                data: finalData,
            }).then((res) => {
                disableLoading();
                setSubmitting(false);
                if (res && res.data) {
                    props.onHide();
                    props.setTemp(!props.temp)
                    props.setMsg(true)
                }
            }).catch((error) => {
                disableLoading();
                setSubmitting(false);
                if(error?.response?.data?.message === "UnAuthorized!"){
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });
        },
    });

    const closeModel = () => {
        props.onHide();
        setShowPopup(false);
    };

    return (
        <div>
            <Modal
                show={showPopup}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
            >
                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span className="font-weight-bolder">
                                Cancel API ({props.data.srId})
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 font-weight-bolder">
                                Are you sure you want to Cancel API for this
                                customer?
                            </div>
                            <div className="col-12 mt-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-1">
                                            <label htmlFor="comments">
                                                Please Mention the reason to
                                                cancel api
                                            </label>
                                            <textarea
                                                className={`form-control ${getInputClasses(
                                                    "comments"
                                                )}`}
                                                name="comments"
                                                id="comments"
                                                placeholder="Write Reason.."
                                                rows="3"
                                                {...formik.getFieldProps(
                                                    "comments"
                                                )}
                                            ></textarea>
                                            {formik.touched.comments &&
                                            formik.errors.comments ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.comments}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="py-3 justify-content-between">
                        <Button
                            variant="danger"
                            className="font-weight-bold"
                            onClick={() => closeModel(props)}
                        >
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9"
                        >
                            <span>Yes</span>
                            {loading && (
                                <span className="ml-3 spinner spinner-white"></span>
                            )}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};
