import React from "react";

export const viewUserDataColumn = () => [
    {
        Header: "Username",
        accessor: "userName",
        className: "text-center align-middle text-nowrap",
    },
    {
        Header: "Email ID",
        accessor: "email",
        className: "text-center align-middle text-nowrap",
    },
];