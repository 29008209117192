import React from "react";
import { toAbsoluteUrl } from "../../../../_helpers";
import moment from "moment-timezone";

const RecentOrderTbody = (props) =>{
    return (
        <>
            <tr>
                <td className=" align-middle">
                    {   props.status === "1"
                        ?   <span className="label label-light-warning label-pill label-inline">Pending</span>
                        : props.status === "2" ? 
                            <> {props.oSupplierStatus === "2"
                                ?  <span className="label label-light-danger label-pill label-inline">Cancelled</span>
                                :  <span className="label label-light-danger label-pill label-inline">Released</span>}
                            </> 
                        : props.status === "3" ? <span className="label label-light-success label-pill label-inline">Approved</span>
                        :  (
                                <>
                                    {/* confitm history status */}
                                    {props.status === "PENDING" || props.status === "READY_TO_PICKUP" ? <span className="label label-light-success label-pill label-inline">Confirmed</span> : null}
                                    {props.status === "QC_OK" ? <span className="label label-light-success label-pill label-inline">QC OK</span> : null}
                                    {props.status === "QC_OK_OUT" ? <span className="label label-light-success label-pill label-inline">QC OK OUT</span> : null}
                                    {props.status === "QC REVIEW IN" || props.status === "QC REVIEW OUT"  ? <span className={`label label-light-${props.supplierMgrAppr === 'N' ? "danger" : "warning"} label-pill label-inline`}>QC REVIEW</span> : null}
                                    
                                    {/* salse history status */}

                                    { props.status === "INTAKE" || props.status === "PICKUP_DONE" || props.status === "TRANSIT" || props.status === "RECEIVED" || props.status === "SHIPPED" ?
                                           <span className="label label-light-success label-pill label-inline">Delivered</span> : null 
                                    }

                                   
                                </>
                           )
                    }
                    {/* <span className={`label label-light-${props.status} label-pill label-inline label-lg"`}>{props.status}</span> */}
                </td>
                <td className="text-center text-nowrap align-middle">{ props.createdDate ? moment.tz(props.createdDate, props.userinfo_timezone ? props.userinfo_timezone : "Asia/Kolkata").format("YYYY-MM-DD") : "-"}</td>
                <td className="text-center text-nowrap align-middle">
                    {props.diamondType === "PN" || props.diamondType === "PL" ? 
                        <div className="d-flex">
                            <div className="pr-5">
                                <img
                                    className="img-fluid mr-2"
                                    alt={props.cShape}
                                    src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${props.cShape.toLowerCase()}.svg`)}
                                    width="50px"
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/svg_shape/parcel/other.svg`
                                            )
                                        }}
                                />
                            </div>
                            <div className="text-left">
                                <div className={`mb-1 ${props.diamondType === "PN" ? "natTab" : "labTab"} d-flex align-items-center`}>
                                    <span className={`${props.diamondType}_diamond small`}>
                                        {props.diamondType}
                                    </span>
                                    <span className={`text-primary small ml-3`}>
                                        {props.diamondType}-{props.parcelId}-{props.splitId}{props.cgSplitId ?<>/{props.cgSplitId}</> : ""}
                                    </span>
                                    <span className={`text-primary text-capitalize small ml-3`}>
                                        {props.lengthMm} - {props.widthMm} MM
                                    </span>
                                </div>

                                <span className="text-dark font-weight-bold d-block">
                                    {props.cShape}{" "}
                                    {props.weight}Ct.{" "}
                                    {props.color}{" "}
                                    {props.clarity}{" "}
                                </span>
                            </div>
                        </div>
                    :
                        <div className="d-flex">
                            <div className="pr-5">
                                {props.awsImage &&  props.awsImage !=="null" ? (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={props.cShape}
                                        src={props.awsImage}
                                        width="50px"
                                        height="50px"
                                        onError={toAbsoluteUrl(
                                            `/media/shape/${props.cShape ? props.cShape.toLowerCase() : ""}-no.png`
                                        )}
                                    />
                                ) : (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={props.cShape}
                                        src={toAbsoluteUrl(
                                            `/media/shape/${props.cShape ? props.cShape.toLowerCase() : ""}-no.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/noimage.jpg`
                                            )
                                        }}
                                        width="50px"
                                        height="50px"
                                        style={{ backgroundColor: "#f4f4f4" }}
                                    />
                                )}
                            </div>
                            <div className="text-left">
                                <div className={`mb-1 d-flex align-items-center ${props.diamondType === "W" || props.diamondType ==="N" ? "natTab" : "labTab"}`}>
                                    <span className={`${ props.diamondType === "W" || props.diamondType ==="N" ? props.cColor === "fancy" ? "F" : "W" : props.cColor === "fancy" ? "LF" : "L"  }_diamond small`}>
                                        {props.diamondType === "W" || props.diamondType ==="N" ? props.cColor === "fancy"  ? "NF" : "N" : props.cColor === "fancy" ? "LF" : "L"}
                                    </span>
                                    <span className={`text-primary small ml-3`}>
                                        {props.loatNo}
                                    </span>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primary mx-2 small"
                                        href={
                                            props.lab === "IGI"
                                                ? `https://www.igi.org/viewpdf.php?r=${props.certiNo}`
                                                : props.lab === "GIA"
                                                ? `https://www.gia.edu/report-check?reportno=${props.certiNo}`
                                                : props.lab === "HRD"
                                                ? `http://ws2.hrdantwerp.com/HRprops.CertificateService.WebAPI/certificate?certificateNumber=${props.certiNo}`
                                                : props.lab === "GCAL"
                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.certiNo}`
                                                : props.Certi_link
                                        }
                                    >
                                        {props.lab}-{props.certiNo}
                                    </a>
                                </div>

                                <span className="text-dark font-weight-bold d-block">
                                    {props.cShape}{" "}
                                    {props.cWeight}{" "}
                                    {props.cColor === "fancy" ? (
                                        <>
                                            {props.fIntensity}{" "}{props.fOvertone}{" "}{props.fColor}{" "}
                                        </>
                                    ) : (
                                        <>{props.cColor}{" "}</>
                                    )}
                                    {props.cClarity}{" "}{props.cCut}{" "}{props.cPolish}{" "}{props.cSymmetry}{" "}
                                    {props.cFluorescence}
                                </span>
                            </div>
                        </div> 
                        }
                </td>
               
                <td className="text-right text-nowrap pr-5 align-middle">
                    <span className="font-weight-bolder text-primary">
                        ${parseFloat(props.price).toFixed(2)}
                    </span>{" "}
                    <br />
                    <span className="text-muted small">
                        $/CT ${parseFloat(props.oRate).toFixed(2)}
                    </span>
                </td>
            </tr>
        </>
    )
}

export default RecentOrderTbody;