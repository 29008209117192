/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from "react";
import LoginSideImg from "./Images/login-img.png";
import { Link ,useParams } from "react-router-dom";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";

export function VeryfyMail() {
  const {token} = useParams()
  // console.log(token)
  const [expiredMsg, setExpiredMsg] = useState(null);


  useEffect(() => {
    document.title = "Verify Mail | Diamonds on Call - Suppliers Center";

    let isMounted = true;
      const finalData = {
        "emailToken":token,
      };
      axios({
          method: "POST",
          url: `${NODE_API_URL}/SUPP/email-verify`,
          headers: { "Content-Type": "application/json" },
          data: finalData,
      }).then((res) => {
          if (res && res.data) {
              isMounted && setExpiredMsg(res.data.message);
          }
      }).catch((res) => {
      });
      

  },[]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{ backgroundColor: "#EEF1F6" }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-center">
              {/* start:: Aside content */}
                <div className="aside-img text-center" >
                  <img src={LoginSideImg} alt="Login_img" title="Login" />
                </div>
              {/* end:: Aside content */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-lg-0">
                {/* <LoginForm props={props} /> */}
                {expiredMsg === "verification done" ? 
                  <div className="text-center">
                    <img className="mb-5" alt="verified" src={toAbsoluteUrl("/media/General/tick.png")}/>
                    <h2 className="font-weight-bolder d-block text-primary">Congratulations ! Your email is Verified.</h2>
                    <p className="text-muted font-size-lg">If you don't redirect to login page.</p>
                      <Link
                          to="/auth/login"
                          className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                      >
                          <span>Click to Login</span>
                      </Link>
                    </div>
                  :
                    <div className="text-center">
                      <img className="mb-5" width="100px" alt="verified" src={toAbsoluteUrl("/media/General/warning.png")}/>
                      <h2 className="font-weight-bolder d-block text-primary">The link you followed has expired</h2>
                        <Link
                            to="/"
                            className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                        >
                            <span>Go Home</span>
                        </Link>
                    </div>
                  }
                {/* <ContentRoute path="/auth/login" component={Login} /> */}
              </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

export default VeryfyMail;