import React, { useEffect } from "react";
import { useSubheader } from "../../layout";
import { CompanyInformation } from "./components/CompanyInformation";
import { CompanyRefdata } from "./components/CompanyRefdata";
import { PersonaInformation } from "./components/PersonaInformation";
import { ProfileCard } from "./components/ProfileCard";
import {Tab} from "react-bootstrap";

export function ProfilePage({userinfo,companyRef}) {
    const suhbeader = useSubheader();
    suhbeader.setTitle("User profile");

    useEffect(() => {
        document.title = "User Profile | Diamonds on Call - Suppliers Center";
    }, []);

    return (
        <Tab.Container id="profileTab" defaultActiveKey="personal">
            <div className="d-flex flex-row">
                <ProfileCard user={userinfo} />
                <div className="flex-row-fluid ml-lg-8">
                    <Tab.Content className="h-100 profileTab">
                        <Tab.Pane eventKey="personal">
                            <PersonaInformation user={userinfo} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="company">
                            <CompanyInformation user={userinfo} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="companyref">
                            <CompanyRefdata data={companyRef} />
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </div>
        </Tab.Container>
    );
}
