import React, { useEffect } from "react";
import { useSubheader } from "../../layout";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { SuccessHistory } from "./SuccessHistory/SuccessHistory";
import { ErrorHistory } from "./ErrorHistory/ErrorHistory";
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../env_config';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const UploadHistory = ({userinfo,subId}) => {
    const [editPerm, setEditPerm] = React.useState(0)
    const [readPerm, setReadPerm] = React.useState(0)
    const [loader,setLoader] = React.useState(true)

    const suhbeader = useSubheader();
    suhbeader.setTitle("Upload History");

    useEffect(() => {
        document.title =
            "Upload History | Diamonds on Call - Suppliers Center";

        let isMounted = true;

        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":8
            };
            axios({
                method: "GET",
                url: `${NODE_API_URL}/SUPP/fetch-supp-permission`,
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
                params: permiFetchData,
              }).then((res) => {
                  setLoader(false)
                  if (res && res.data) {
                      isMounted && setEditPerm(res.data.data[0].editPermission);
                      isMounted && setReadPerm(res.data.data[0].readPermission);                  }
              }).catch((res) => {
                  setLoader(false)
                  if(res?.response?.data?.message === "UnAuthorized!"){
                      localStorage.clear();
                      window.location.replace(`${BASE_URL}/logout`);
                  }
              });
          }else{
              setLoader(false)
          }
    }, [subId]);

    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
        setValue(newValue);
    }


    return (
        <>
            {!loader ?
                <>
                    {readPerm || !subId ?
                        <>
                            {userinfo.uploadMode === "File" ? 
                                <div className="card card-custom gutter-b">
                                    <div className="card-header tabbed-card pb-0" style={{minHeight:"50px!important"}}>
                                        <div className="card-title">
                                            <span className="card-label font-weight-bolder text-dark">
                                                Upload History
                                            </span>
                                        </div>
                                        <div className="card-toolbar m-0 flex-md-nowrap">
                                        <Paper position="static" className="shadow-none border-bottom">
                                            <Tabs value={value} indicatorColor="secondary" textColor="secondary" onChange={handleTabChange}>
                                                <Tab label="Upload List" />
                                                <Tab label="Error List"  />
                                            </Tabs>
                                        </Paper>
                                        </div>
                                    </div>
                                    {value === 0 && (
                                        <TabContainer>
                                            <SuccessHistory userinfo={userinfo} />
                                        </TabContainer>
                                    )}
                                    {value === 1 && (
                                        <TabContainer>
                                            <ErrorHistory userinfo={userinfo} />
                                        </TabContainer>
                                    )}
                                    
                                </div>
                            : 
                                <div className="card card-custom gutter-b">
                                    <div className="card-header tabbed-card pb-0" style={{minHeight:"50px!important"}}>
                                        <div className="card-title">
                                            <span className="card-label font-weight-bolder text-dark">
                                                Upload History
                                            </span>
                                        </div>
                                    </div>
                                    <SuccessHistory userinfo={userinfo} />
                                </div>
                            }
                        </>
                    :
                        <div className="card card-custom gutter-b">
                            <div className="card-header py-3 mb-3">
                                <div className="card-title">
                                    <span className="card-label font-weight-bolder text-dark">
                                        Upload History
                                    </span>
                                </div>
                            </div>
                            <div className="card-body text-center">
                                You do not have permission to access, Please contact your administrator for help
                            </div>
                        </div>
                    }   
                </>
            :
                <div className="card card-custom gutter-b">
                    <div className="card-header py-3 mb-3">
                        <div className="card-title">
                            <span className="card-label font-weight-bolder text-dark">
                            Upload History
                            </span>
                        </div>
                    </div>
                    <div className="card-body d-flex justify-content-center font-weight-bold font-size-lg">
                        Loading...<div className="spinner spinner-primary ml-5"></div>
                    </div>
                </div>
            } 
        </>
    );
};
