import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../../env_config';
// import axios from "axios"
// import {NODE_API_URL} from "../../../../../env_config";
// import { connect } from "react-redux";
// import { FormattedMessage, injectIntl } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { login } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    drange: "",
    type: "",
};

function ProfileSetupForm({userinfo}) {
  // const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    drange: Yup.string().required("Diamond Range is Required"),
    type: Yup.string().required("Diamond Type is Required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      const finalData = {
        "drange":values.drange,
        "type":values.type,
    }
    setTimeout(() => {
      axios({
        method: 'POST',
        url: `${NODE_API_URL}/SUPP/profile-setup`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        },
        data: finalData
      })
        .then(function (res) {
          // console.log(res)
          disableLoading();
          setSubmitting(false);
          window.location.replace("/")
       
        })
        .catch((res) => {
          // console.log(res.response)
          disableLoading();
          setSubmitting(false);
          if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
        }
        //   setStatus(res.response.data.error);
        });
    }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="mb-5">
        <h1>You're almost done</h1>
        <p className="text-muted font-weight-bold">Thank you for choosing Diamonds On Call - supplier Center, to help us give you better experience answer the questions below.</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container" >
          <label htmlFor="drange">How many diamonds do you have?<span className="text-danger"> *</span></label>
          <select className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
              "drange"
            )}`} placeholder="Select Range"  name="drange" {...formik.getFieldProps("drange")}>
              <option value=""> Select Range</option>
              <option value="1-100">1-100</option>
              <option value="101-1000">101-1000</option>
              <option value="1001-2000">1001-2000</option>
              <option value="2001-4000">2001-4000</option>
              <option value="4000">4000 & above</option>
          </select>
          {formik.touched.drange && formik.errors.drange ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.drange}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label htmlFor="type">What type of diamond do you sell ?<span className="text-danger"> *</span></label>
          <select className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
              "type"
            )}`} name="type" placeholder="Select Range" {...formik.getFieldProps("type")}>
            <option value="">Select Diamond Type</option>
            <option value="Natural">Natural</option>
            <option value="Lab Grown">Lab Grown</option>
          </select>
          {formik.touched.type && formik.errors.type ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.type}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
          >
            <span>Continue</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default ProfileSetupForm;


