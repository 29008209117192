/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Alert, Nav } from "react-bootstrap";
// import { shallowEqual, useSelector } from "react-redux";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { BASE_URL_OLD,BASE_URL } from "../../../../env_config";

export function ProfileCard(props) {
    // const user = useSelector(({ auth }) => auth.user, shallowEqual);
    // console.log(props);
    const [imageBroken, setImageBroken] = React.useState(false);
    return (
        <>
            {props && (
                <div
                    className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                    id="kt_profile_aside"
                >
                    <div className="card card-custom card-stretch">
                        {/* begin::Body */}
                        <div className="card-body pt-4">
                            {/* begin::User */}
                            <div className="d-flex flex-column flex-center my-10">
                                <div className="symbol symbol-120 symbol-circle symbol-success overflow-hidden border-success border border-2 profileLogo">
                                  {props.user.supplierLogo ?
                                      <>
                                        {imageBroken ? 
                                            <img
                                              alt="logo"
                                              src={toAbsoluteUrl(`/media/General/DOC_LOGO.png`)}
                                            />
                                          :
                                            <img
                                                src={`${BASE_URL_OLD}/assets/documents/${props.user.supplierLogo}`}
                                                alt="logo"
                                                onError={() => setImageBroken(true)}
                                            /> 
                                          }
                                      </> 
                                    :
                                      <img
                                        alt="logo"
                                        src={toAbsoluteUrl(`/media/General/DOC_LOGO.png`)}
                                      />
                                    }
                                </div>
                                <p className="card-title font-weight-bolder text-dark-75 font-size-h4 m-0 pt-7 pb-1">
                                    {props.user.supplierNameO}
                                </p>
                                <div className="font-weight-bold text-dark-50 font-size-sm pb-6">
                                    {props.user.designation}
                                </div>
                            </div>
                            {/* end::User */}
                            {/* begin::Nav */}
                            {/* <div className="navi navi-bold navi-hover navi-active navi-link-rounded"> */}
                                <Nav
                                    variant="pills"
                                    className="flex-column mt-md-10 mt-3 nav-bold nav-hover nav-active nav-link-rounded"
                                >
                                    <Nav.Item>
                                        <Nav.Link eventKey="personal">
                                            <span className="nav-icon mr-2">
                                                <span className="svg-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/General/User.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </span>
                                            <span className="nav-text font-size-lg">
                                                Personal Information
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="company">
                                            <span className="nav-icon mr-2">
                                                <span className="svg-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Code/Compiling.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </span>
                                            <span className="nav-text font-size-lg">
                                                Company Information
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="companyref">
                                            <span className="nav-icon mr-2">
                                                <span className="svg-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Communication/Group.svg"
                                                        )}
                                                    ></SVG>{" "}
                                                </span>
                                            </span>
                                            <span className="nav-text font-size-lg">
                                                Company Reference
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                {/* <div className="navi-item mb-2">
                  <NavLink to="/profile/personal-information" className="navi-link py-4" activeClassName="active" >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/User.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Personal Information
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink to="/profile/company-information" className="navi-link py-4" activeClassName="active" >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Compiling.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Company Information
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink to="/profile/company-reference" className="navi-link py-4" activeClassName="active" >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Group.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      Company Reference
                    </span>
                  </NavLink>
                </div> */}
                            {/* </div> */}
                            {/* end::Nav */}
                        </div>
                        {/* end::Body */}
                    </div>
                </div>
            )}
        </>
    );
}
