import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { NODE_API_URL } from "../../../../env_config";
import { Alert, Col, Form, Row } from "react-bootstrap";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    otp: "",
    mailsent: false,
    otpverified: false,
};

function ForgotForm() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(0);
    const inputRefs = useRef([]);
    const [otptimer, setOtpTimer] = useState(0);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Email Id is required"),
        newPassword: Yup.string("Enter your new password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required")
            .matches(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                "Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"
            ),
        confirmNewPassword: Yup.string("Enter your new password")
            .oneOf([Yup.ref("newPassword"), null], "Password must match")
            .required("Confirm Password Password is Required"),
           
        otp: Yup.string()
            .required("OTP is required")
            .length(6, "OTP must be exactly 6 digits"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    useEffect(() => {
        const countdown = setInterval(() => {
          if (timer > 0) {
            setTimer(timer - 1);
          }
        }, 1000);
        return () => clearInterval(countdown);
      }, [timer]);

    useEffect(() => {
        const otpcountdown = setInterval(() => {
            if (otptimer > 0) {
                setOtpTimer(otptimer - 1);
            }
        }, 1000);
        return () => clearInterval(otpcountdown);
    }, [otptimer]);

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return;

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);
        formik.setFieldValue("otp", newOtp.join(""));
        // Move focus to the next input if there is one
        if (element.value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            if (otp[index] === "") {
                // Move focus to the previous input
                if (index > 0) {
                    inputRefs.current[index - 1].focus();
                }
            } else {
                // Clear the current input
                const newOtp = [...otp];
                newOtp[index] = "";
                setOtp(newOtp);
                formik.setFieldValue("otp", newOtp.join(""));
            }
        }
    };

    const sendMail = () => {
        // Validate only the email field
        LoginSchema.validateAt("email", formik.values)
            .then(() => {
                enableLoading();
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/SUPP/forget-password`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                    data: {
                        mail: formik.values.email,
                    },
                })
                    .then((res) => {
                        if (res && res.data) {
                            disableLoading();
                            if (!res.data.success) {
                                setError(res.data.msg);
                            } else {
                                if (res.data.msg === "OTP sent successfully.") {
                                    setError("");
                                    setTimer(30);
                                    setOtpTimer(300);
                                    formik.setFieldValue("mailsent", true);
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        disableLoading();
                        if (error?.response?.data?.message) {
                            setError(error?.response?.data?.message);
                        }
                    });
            })
            .catch((validationError) => {
                // If email validation fails, handle the error
                setError(validationError.message);
            });
    };
    
    

    const validateOtp = () => {
        // Validate only the otp field
        LoginSchema.validateAt("otp", formik.values)
            .then(() => {
                enableLoading();
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/SUPP/validate-otp`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                    data: {
                        email: formik.values.email,
                        otp: formik.values.otp,
                    },
                })
                    .then((res) => {
                        if (res && res.data) {
                            disableLoading();
                            if (!res.data.success) {
                                setError(res.data.message);
                            } else {
                                if (res.data.message === "OTP Verified Successfully!") {
                                    setError("");
                                    formik.setFieldValue("otpverified", true);
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        disableLoading();
                        console.log(error.response.data)
                        if (error?.response?.data?.message) {
                            setError(error?.response?.data?.message);
                        }
                    });
            })
            .catch((validationError) => {
                // If otp validation fails, handle the error
                setError(validationError.message);
            });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            // Validate newpassword and confirmpassword fields
            Promise.all([
                LoginSchema.validateAt("newPassword", values),
                LoginSchema.validateAt("confirmNewPassword", values),
            ])
                .then(() => {
                    enableLoading();
                    // setTimeout(() => {
                        const finalData = {
                            email: values.email,
                            newPassword: values.newPassword,
                            confirmNewPassword: values.confirmNewPassword,
                        };
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/SUPP/password-reset`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                            },
                            data: finalData,
                        })
                            .then(function (res) {
                                disableLoading();
                                setStatus(res.data.message);
                                setSuccess(res.data.message);
                                if (!res.data.success) {
                                    setError(res.data.message);
                                }
                                setTimeout(() => {
                                    window.location.replace("/auth/login")
                                }, 2000);
                            })
                            .catch(() => {
                                disableLoading();
                                setSubmitting(false);
                            });
                    // }, 1000);
                })
                .catch((validationError) => {
                    // Handle validation errors
                    setError(validationError.message);
                    setSubmitting(false);
                });
        },
    });
    

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="login-form login-signin"
            id="kt_login_signin_form"
        >
            {!formik.values.mailsent ? (
                <>
                    <div className="mb-5">
                        <h1> Password Reset </h1>
                        <p className="text-muted ">
                            If you have forgotten your password, please enter
                            your email address below. We will send a One-Time
                            Password (OTP) to verify your request.
                        </p>
                        <p className="text-muted font-weight-bold">
                            The OTP will remain valid for 5 minutes.
                        </p>
                    </div>
                    <div className="form fv-plugins-bootstrap fv-plugins-framework">
                        {!success ? (
                            <>
                                <div className="form-group fv-plugins-icon-container">
                                    <label htmlFor="type">
                                        Email Address
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
                                            "email"
                                        )}`}
                                        name="email"
                                        {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email &&
                                    formik.errors.email ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.email}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                    <span
                                        id="kt_login_signin_submit"
                                        onClick={() => sendMail()}
                                        className={`cursor-pointer btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                                    >
                                        <span>Send Me OTP</span>
                                        {loading && (
                                            <span className="ml-3 spinner spinner-white"></span>
                                        )}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            ) : !formik.values.otpverified ? (
                <>
                    <div className="mb-5">
                        <h1> Reset Your Password ? </h1>
                        <p className="text-muted mb-2">
                            We just sent a 6 digit OTP. Enter that code here to
                            proceed
                        </p>
                        {error && <Alert variant="danger">
                            {error}
                        </Alert>}
                        {otptimer ? (
                            <>
                                {timer? <p className="font-weight-bold mb-0 text-success">
                                        OTP sent successfully.
                                    </p>:null}
                                <span className="text-muted small">
                                    OTP Expires in{" "}
                                    <span className="text-danger">{`${Math.floor(
                                        otptimer / 60
                                    )
                                        .toString()
                                        .padStart(2, "0")}:${(otptimer % 60)
                                        .toString()
                                        .padStart(2, "0")}`}</span>
                                </span>
                            </>
                        ) : null}
                    </div>
                    <Row
                        className={`form fv-plugins-bootstrap mb-5 fv-plugins-framework ${getInputClasses(
                            "otp"
                        )}`}
                    >
                        {otp.map((data, index) => (
                            <Col key={index}>
                                <Form.Control
                                    type="text"
                                    name="otp"
                                    maxLength="1"
                                    value={data}
                                    onChange={(e) =>
                                        handleOtpChange(e.target, index)
                                    }
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onFocus={(e) => e.target.select()}
                                    ref={(el) =>
                                        (inputRefs.current[index] = el)
                                    }
                                    className={`text-center form-control form-control-solid font-size-h5 font-weight-bolder p-1`}
                                    //   style={{ fontSize: '15px', fontWeight: 'bold',padding:"" }}
                                />
                            </Col>
                        ))}
                    </Row>
                    {formik.errors.otp ? (
                        <div className="text-danger">{formik.errors.otp}</div>
                    ) : null}
                    {!timer ? (
                        <div className="small text-muted">
                            Didn't get OTP ? -{" "}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={() => sendMail()}
                            >
                                Regenerate OTP
                            </span>
                        </div>
                    ) : (
                        <div className="text-muted small">
                            You can request a new OTP in{" "}
                            <span className="text-danger">{`00:${timer
                                .toString()
                                .padStart(2, "0")}`}</span>
                        </div>
                    )}
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <span
                            id="kt_login_signin_submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                            onClick={() => validateOtp()}
                        >
                            <span>Validate OTP</span>
                            {loading && (
                                <span className="ml-3 spinner spinner-white"></span>
                            )}
                        </span>
                    </div>
                </>
            ) : 
                <>
                    <div className="mb-5">
                        <h1>Reset your password</h1>
                        <p className="text-muted ">
                            Enter a new password below to change your new password
                        </p>
                        {success && <Alert variant="success">
                            {success}
                        </Alert>}
                        {error && <Alert variant="danger">
                            {error}
                        </Alert>}
                    </div>
                    <div className="form fv-plugins-bootstrap fv-plugins-framework">
                        <div className="form-group fv-plugins-icon-container">
                            <label htmlFor="type">
                                New Password
                                <span className="text-danger"> *</span>
                            </label>
                            <input
                                placeholder="New password"
                                type="password"
                                className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
                                    "newPassword"
                                )}`}
                                name="newPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                {...formik.getFieldProps("newPassword")}
                            />
                            {formik.touched.newPassword &&
                            formik.errors.newPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.newPassword}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                            <label htmlFor="type">
                                Confirm New Password
                                <span className="text-danger"> *</span>
                            </label>
                            <input
                                placeholder="confirm new password"
                                type="password"
                                className={`form-control form-control-solid h-auto py-4 mb-4 px-6 ${getInputClasses(
                                    "confirmNewPassword"
                                )}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="confirmNewPassword"
                                {...formik.getFieldProps("confirmNewPassword")}
                            />
                            {formik.touched.confirmNewPassword &&
                            formik.errors.confirmNewPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.confirmNewPassword}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group d-flex flex-wrap align-items-center">
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={formik.isSubmitting}
                                className={`btn btn-primary btn-sm font-weight-bold px-9 py-3 my-2`}
                            >
                                <span>Reset Password</span>
                                {loading && (
                                    <span className="ml-3 spinner spinner-white"></span>
                                )}
                            </button>
                        </div>
                        <em className='form-group d-flex flex-wrap small'>
                            <b className="pr-2">Note :{"  "}</b>Do not refresh the page until the password setup is complete. 
                        </em>
                    </div>
                    
                </>
            }
        </form>
    );
}

export default ForgotForm;
