import React,{useState} from "react";
import { Link } from "react-router-dom";
import { BASE_URL, NODE_API_URL } from "../../../env_config";
import axios from "axios";
import { SwalWarn} from "../popup";

export const ApiMode = ({userinfo,editPerm,readPerm,subId}) => {
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [successMsg,setSuccessMsg] = useState()
    const [subMessage,setSubMessage] = useState()
    const [apiKey,setApiKey] = useState(userinfo.apiKey)
    const [loader,setLoader] = useState(false)

    const generateApifn = () => {
        setLoader(true)
        axios({
            method: "PATCH",
            url: `${NODE_API_URL}/SUPP/api-upload`,
            headers: {
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
                 "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
        })
        .then((res) => {
            if (res && res.data) {
                setLoader(false)
                setSwalWarnOpen(false)
                setApiKey(res.data.api_key)
                setSuccessMsg(res.data.message)
            }
        })
        .catch((res) => {
            setLoader(false)
            if(res?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
              }
        });
       
    }
    const generateApi = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Are you sure want to Generate !!")
        setSubMessage("Your old API is going to invalid !!")
    }
    return (
        <>
            <div className="card-body pb-10">
                <h3 className="mb-1">API Details</h3>
                <span className="text-muted">
                    Use the API key below to upload your Diamond inventory using API.
                </span>
                {successMsg && (
                    <div className="mt-5 alert alert-custom alert-light-success alert-dismissible px-5 py-3">
                        <div className="alert-text font-weight-bold">
                            {successMsg}
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-8 mt-5">
                        <div className="border border-secondary">
                            <div className="px-5 py-3 font-size-h5 font-weight-bold border-bottom">API Key</div>
                            <div className="px-5 py-3 bg-gray-100">{apiKey ? apiKey : null}</div>
                        </div>
                    </div>
                    <div className="col-md-8 text-md-right mt-5">
                        <p className="font-size-lg">If you need a NEW API KEY click the button below. Please note once you generate a New API Key, your Old API Key will be invalid</p>
                        {editPerm || !subId?
                            <button type="submit" name="name" id="btnSubmit" className="btn btn-lg btn-primary px-8" onClick={() => generateApi()} disabled={loader}>Generate new API</button> : ""
                        }
                    </div>
                    <div className="col-md-8">
                        To know more on how to upload the inventory using API<br/>
                       <Link to={userinfo.type === "Natural" ? "/apimode-help" : "/lab-apimode-help"} target="_blank"> Read API Documentation</Link>
                    </div>
                    <div className="col-md-8 mt-5">
                        <div className="border-warning font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-warning-o-20" style={{borderLeft:"5px solid"}}><span className="font-weight-bold">Note:</span> If you would like to change your upload method, please contact your purchase representative</div>
                    </div>
                </div>
                <SwalWarn show={swalWarnOpen} message={alertMessage} subMessage={subMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => generateApifn()}/> 
            </div>
        </>
    );
};
