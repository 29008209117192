/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../_helpers";
import { FaCheckDouble } from "react-icons/fa";

export function ConfirmHistoryTile({confirmHistoryCount,userinfo}) {
  // console.log(JSON.stringify(confirmHistoryCount.confirmHistoryCount))
  return (
    <>
      <div className={`card card-custom confhistory card-stretch gutter-b`} style={{ height: "120px" }} >
        {userinfo.isPending === 1 ?
        <>
          <div>
            <div className="card-body">
              <div className="text-white font-weight-bolder font-size-h2 mt-3">{confirmHistoryCount !== "" ? confirmHistoryCount :"0" }</div>
              <span className="text-light font-weight-bold font-size-lg mt-1"> No. of Stones in confirm history</span>
              <div className="bgIcon">
                <FaCheckDouble />
              </div>
            </div>
          </div>
      </>
        : <Link to="/confirm-history" >
        <div className="card-body">
          <div className="text-white font-weight-bolder font-size-h2 mt-3">{confirmHistoryCount !== "" ? confirmHistoryCount :"0" }</div>
          <span className="text-light font-weight-bold font-size-lg mt-1"> No. of Stones in confirm history</span>
          <div className="bgIcon">
            <FaCheckDouble />
          </div>
        </div>
      </Link>}
        
      </div>
    </>
  );
}
