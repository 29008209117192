import React, { useMemo, useState,useEffect } from "react";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
import { viewUserDataColumn } from "./ViewUserColumns";

import { 
    FaSortDown,
    FaSortUp,
    FaTrashAlt,
    FaEdit,
    FaKey
} from "react-icons/fa";
import { PermissionPopup } from "../../../popup/PermissionPopup";
import { EditUserPopup } from "../../../popup/EditUserPopup";
import { SwalError } from "../../../popup/";
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../../../env_config';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ViewUserTable = ({userinfo,updateTemp,temp}) => {

    const [permissionOpen,setPermissionOpen] = useState(false)
    const [editOpen,setEditOpen] = useState(false)
    const [showUserData,setShowUserData] = useState([])

    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()

    const [loader,setLoader] = useState(true)
    const [data,setData] = useState([])
    const [successMsg ,setSuccessMsg] =useState()
    const [errorMsg ,setErrorMsg] =useState()


    useEffect(() => {
        let isMounted = true;
          
          axios({
              method: "POST",
              url: `${NODE_API_URL}/SUPP/fetch-supp-group`,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
              data: {"supp_id":userinfo.id},
          }).then((res) => {
              if (res && res.data.data) {
                setLoader(false)
                  isMounted && setData(res.data.data);
                  
              }
          }).catch((res) => {
            setLoader(false)
            if(res?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
            }
          });
          
    }, [userinfo.id,temp]);

    const swalErrorOpenfn = (row) => {
        setSwalErrorOpen(true)
        setShowUserData(row.original)
        setAlertMessage("Are you Sure wanted to Delete User ?")
    }

    const columns = useMemo(() => viewUserDataColumn(userinfo), [userinfo]);

    const deleteUser = (row) => {
        const finalData = {
            "sub_supp_id": row.subSuppId,
            "supp_mail": userinfo.email,
            "is_delete": "1",
            "username": row.userName,
            "sup_cname":userinfo.supplierName
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/update-into-supp-group`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: finalData,
        }).then((res) => {
        //   console.log(res)
            if (res && res.data) {
                let isMounted = true;
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/SUPP/fetch-supp-group`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                      },
                    data: {"supp_id":userinfo.id},
                }).then((res) => {
                    if (res && res.data.data) {
                        setSuccessMsg()
                        setErrorMsg("User Deleted Successfully !!")
                        setSwalErrorOpen(false)
                        setLoader(false)
                        isMounted && setData(res.data.data);
                        const timeId = setTimeout(() => {
                            setErrorMsg()
                        }, 5000)
                    
                        return () => {
                            clearTimeout(timeId)
                        }
                    }
                }).catch((res) => {
                  setLoader(false)
                  if(res?.response?.data?.message === "UnAuthorized!"){
                        localStorage.clear();
                        window.location.replace(`${BASE_URL}/logout`);
                    }
                });
               
            }
        }).catch((res) => {
          setLoader(false)
          if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
        }
        });
    }

    const editUser = (row) => {
        setEditOpen(true)
        setShowUserData(row.original)
    }
    const editPermission = (row) => {
        setPermissionOpen(true)
        setShowUserData(row.original)
    }

    

    const loginOnOff = (e,row) => {
        
        const finalData = {
            "sub_supp_id": row.original.subSuppId,
            "login_on_off": e.target.checked ? "1":"0"
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/update-into-supp-group`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: finalData,
        }).then((res) => {
            if (res && res.data) {
                let isMounted = true;
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/SUPP/fetch-supp-group`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                      },
                    data: {"supp_id":userinfo.id},
                }).then((res) => {
                    if (res && res.data.data) {
                       
                        setLoader(false)
                        isMounted && setData(res.data.data);
                        setSuccessMsg("User Updated successfully !!")
                        const timeId = setTimeout(() => {
                            setSuccessMsg()
                        }, 5000)
                    
                        return () => {
                            clearTimeout(timeId)
                        }
                    }
                }).catch((res) => {
                  setLoader(false)
                  if(res?.response?.data?.message === "UnAuthorized!"){
                        localStorage.clear();
                        window.location.replace(`${BASE_URL}/logout`);
                    }
                });
               
            }
        }).catch((res) => {
          setLoader(false)
          if(res?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
            }
        });
    }

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    ...columns,
                    {
                        Header: () => "Login On/Off",
                        id: "loginOnOff",
                        Cell: ({ row }) => (
                            <label className="switch switch-sm switch-primary justify-content-center mb-0">
                                <input type="checkbox" checked={row.original.loginOnOff === "1" && true} onChange={(e) => loginOnOff(e,row)} />
                                <span></span>
                            </label>
                        ),
                        className:"text-center align-middle",
                       
                    },
                    {
                        Header: () => "Action",
                        id: "delete",
                        Cell: ({ row }) => (
                            <>
                                <span>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip >Click to edit user</Tooltip>} >
                                        <button className="btn btn-sm btn-light-primary btn-icon" onClick={() => editUser(row)}><FaEdit className="font-size-h5"/></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip >Click to edit Permissions</Tooltip>} >
                                        <button className="btn btn-sm btn-light-warning btn-icon ml-3" onClick={() => editPermission(row)}><FaKey className="font-size-h5"/></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip >Click to Delete user</Tooltip>} >
                                        <button className="btn btn-sm btn-light-danger btn-icon ml-3" onClick={() => swalErrorOpenfn(row)}><FaTrashAlt className="font-size-h5"/></button>
                                    </OverlayTrigger>
                                </span>
                                
                            </>
                        ),
                        className:"text-center align-middle",
                       
                    },
                ];
            });
        }
    );
    return (
        <>
        {!loader ? 
            <div className="card-body p-0">
                {successMsg && 
                    <div className="mb-5 alert alert-custom alert-light-success mb-4 py-4 alert-dismissible">
                        <div className="alert-text font-weight-bold">
                        {successMsg}
                        </div>
                    </div>
                }
                {errorMsg && 
                    <div className="mb-5 alert alert-custom alert-light-danger mb-4 py-4 alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {errorMsg}
                        </div>
                    </div>
                }
                <div className="table-responsive custom-scrollbar w-100 mt-4">
                    <table className="table table-striped table-hover custom_datatable api_datatable" {...getTableProps()} >
                            <thead className="custom_datatable_head">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(
                                        (column, i) => (
                                            <th key={i} className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                                <span className="text-secondary">
                                                    {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                </span>
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {data.length !== 0 ? page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={row.id}>
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <td className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className,})}key={i}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }) :
                            <tr>
                                <td className="text-center align-middle"colSpan="100%">
                                    No user available for manage
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                <PermissionPopup show={permissionOpen} onHide={() => setPermissionOpen(false)} data={showUserData} />
                <EditUserPopup show={editOpen} onHide={() => setEditOpen(false)} data={showUserData} updateTemp={()=> updateTemp()} />
                <SwalError show={swalErrorOpen} message={alertMessage} onHide={() => setSwalErrorOpen(false)} onClose={() => setSwalErrorOpen(false)} responseOnOk={()=> deleteUser(showUserData)}/> 
                
                {/* <PendingApiApprove show={approveApiOpen} onHide={() => setApproveApiOpen(false)} data={showDiamData} userinfo={userinfo} /> */}
            </div>
            : <div className="card-body p-0 d-flex justify-content-center font-weight-bold font-size-lg">Loading...<div className="spinner spinner-primary ml-5"></div></div>}
        </>
    );
};
