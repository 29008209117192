import React, { useMemo, useEffect,useState } from "react";
import { useSubheader } from "../../layout";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
import { searchResultColumn } from "./Columns";
import { GlobleFilter } from "./../tableFilter";
import { CheckBox } from "./../tableFilter";
import { Dropdown, Modal } from 'react-bootstrap';
import ExpandedDetails from "../expandedDetails/ExpandedDetails"
import { FaSortDown,FaSortUp,FaAngleLeft,FaAngleRight,FaAngleDoubleLeft,FaAngleDoubleRight,FaChevronDown, FaChevronRight,FaEllipsisV} from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ActiveShipping } from "../popup/ActiveShipping";
import SearchTableSkeleton from "../SkeletonLoaders/SearchTable";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../env_config";
import { RemoveStones } from "../popup/RemoveStones";
import { useLocation } from "react-router-dom";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const SearchResult = ({userinfo}) => {
    const location = useLocation();
    const isStockSearch = location.pathname.includes('stock-search');
    const [stoneData, setStoneData] = useState([]);
    const [shipPopup,setShipPopup] = useState(false)
    const [removeStonePopup,setRemoveStonePopup] = useState(false)
    const [successMsg,setSuccessMsg] = useState(null)
    const [temp,setTemp] = useState(false)
    const [shipStone,setShipStone] = useState([])
    const [loader,setLoader] = useState(true)
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);

    const [totalDiamonds, setTotalDiamonds] = useState("0");
    const [totalDollarCarat, setTotalDollarCarat] = useState("0");
    const [totalPrice, setTotalPrice] = useState("0");
    const [totalCarat, setTotalCarat] = useState("0");

    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <ExpandedDetails row={row.row.original} userinfo={userinfo} />
            </>
        ),[]
      )
    const suhbeader = useSubheader();

    suhbeader.setTitle("Search Result");
    document.title ="Search Result | Diamonds on Call - Suppliers Center";
  console.log(stoneData)
    useEffect(() => {
        if(isStockSearch){
            setStoneData(location.state.searchresult||[])
            setLoader(false)
        } else {
            let finalSerchData = window.localStorage.getItem("fetchData")
            if(finalSerchData){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/SUPP/new-search`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                    },
                    data: finalSerchData,
                })
                .then(function(res) {
                    if(res && res.data &&res.data.data){
                        // console.log("searchRes",res.data.data)
                        setStoneData([...res.data.data]);
                        setLoader(false)
                    }
                }).catch(function(res) {
                    // console.error(res);
                    setLoader(false)
                    if(res?.response?.data?.message === "UnAuthorized!"){
                        localStorage.clear();
                        window.location.replace(`${BASE_URL}/logout`);
                    }
                });
            }
        }

    }, [temp,location.state]);
    

    const activeShippSwal =(selectedFlatRows)=>{
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }

        // Check if any of the selected diamonds are already activated
        const alreadyActivated = selectedFlatRows.some(
            (row) => row.original?.custTypeNo === "0"
        );

        if (alreadyActivated) {
            alert("One or more selected diamonds are already activated for shipment");
            return;
        }

        let temptotaldiamonds = 0
        let temptotalprice = 0
        let diamondcarat = 0
        selectedFlatRows.map(value => {
            temptotaldiamonds += 1
            temptotalprice += parseFloat(value.original.cWeight * value.original.oRate)
            diamondcarat += parseFloat(value.original.cWeight)
            return {
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
            }
        })


        setTotalDiamonds(parseFloat(temptotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice))
        setTotalCarat(parseFloat(diamondcarat))
        setTotalDollarCarat( temptotalprice !== 0 ?  parseFloat(temptotalprice) / parseFloat(diamondcarat) : "00")

        let diamondinfo = selectedFlatRows.map(value => {
            return (
                (value.original)
            )
        })
        setShipStone(diamondinfo)
        setShipPopup(true)

    }


    const removeStones =(selectedFlatRows)=>{
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }

        let temptotaldiamonds = 0
        let temptotalprice = 0
        let diamondcarat = 0
        selectedFlatRows.map(value => {
            temptotaldiamonds += 1
            temptotalprice += parseFloat(value.original.cWeight * value.original.oRate)
            diamondcarat += parseFloat(value.original.cWeight)
            return {
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
            }
        })


        setTotalDiamonds(parseFloat(temptotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice))
        setTotalCarat(parseFloat(diamondcarat))
        setTotalDollarCarat( temptotalprice !== 0 ?  parseFloat(temptotalprice) / parseFloat(diamondcarat) : "00")

        let diamondinfo = selectedFlatRows.map(value => {
            return (
                (value.original)
            )
        })
        setShipStone(diamondinfo)
        setRemoveStonePopup(true)

    }


    const columns = useMemo(() => searchResultColumn(), []);

    
    const globalFilterFunction = (rows, columnIds, filterValue) => {
        return rows.filter(row => {
            const values = Object.values(row.original); // Get all field values of the row
            return values.some(value => 
                String(value).toLowerCase().includes(filterValue.toLowerCase())
            );
        });
    };

    function discount(x){
        
        let orignal_price = parseFloat(x.oRate * x.cWeight).toFixed(2);
        let carat_price = parseFloat(orignal_price / x.cWeight).toFixed(2);
        let discount = x.cRap ? parseFloat((carat_price - x.cRap) / (x.cRap) * 100).toFixed(2) : "0.00" 
        return parseFloat(discount).toFixed(2);
    }

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        selectedFlatRows,
        state: { globalFilter, pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data:stoneData,
            globalFilter: globalFilterFunction,
            initialState: {
                sortBy: [
                    {
                        id: 'cWeight',
                        desc: true
                    }
                ]
            }
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                                
                                <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    {
                        Header: () => null,
                        id: 'expander',
                        Cell: ({ row }) => (
                            <span  {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand" />}
                            </span>
                        ),
                    },
                    ...columns,
                ];
            });
        }
    );
    // setSelectedData(selectedFlatRows)
    return (
        <>
            {!loader ?<div className="card card-custom gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title">
                        <span className="card-label font-weight-bolder text-dark">
                            Search Result <span className="text-primary">(Total stone: {stoneData.length})</span>
                        </span>
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        <button className="btn btn-primary btn-sm text-nowrap mr-2" onClick={()=>activeShippSwal(selectedFlatRows)}>Active Shipment</button>
                        <button className="btn btn-warning btn-sm text-nowrap mr-2" onClick={()=>removeStones(selectedFlatRows)}>Remove Stones</button>
                        <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter}  />
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2 searchResult">
                                    <PerfectScrollbar
                                        options={perfectScrollbarOptions}
                                        className="scroll"
                                        style={{ maxHeight: "400px", position: "relative" }}
                                    >
                                    {/* <div  className="" > */}
                                        <div>
                                            <label className="checkbox my-2 border p-2">
                                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                <span className="mr-2"></span>
                                                Show All
                                            </label> 
                                        </div>
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id} className={`${column.id}`}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    {/* </div> */}
                                    </PerfectScrollbar>
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-5 pb-0">
                    {successMsg ? 
                        <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
                            <div className="alert-text font-weight-bold">
                               {successMsg}
                            </div>
                        </div>
                    : null }
                    <div className="table-responsive custom-scrollbar w-100 mt-4">
                        <table className="table table-striped table-hover custom_datatable" {...getTableProps()} >
                            <thead className="custom_datatable_head">
                                {headerGroups.map((headerGroup, i) => (
                                    <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(
                                            (column, i) => (
                                                <th key={i} className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                    </span>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {stoneData.length !== 0 ? page.map((row) => {
                                    prepareRow(row)
                                    return(
                                        <React.Fragment key={row.id}>
                                            <tr {...row.getRowProps()} key={row.id} className={`${discount(row.original) < (-97.00) ? "bg-danger-o-20" : ""}`}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                            {row.isExpanded ? (
                                                <tr>
                                                    <td className="p-0" colSpan={visibleColumns.length}>
                                                        {renderRowSubComponent({ row })} 
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </React.Fragment>
                                    )
                                }) :
                                <tr>
                                    <td className="text-center align-middle"colSpan="100%">
                                        No data found
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer border-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">
                            Page {pageIndex + 1} of {pageOptions.length}{" "}
                        </span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage} >
                                <FaAngleDoubleLeft />
                            </button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage} >
                                <FaAngleLeft />
                            </button>
                            <span className="font-weight-bold text-muted">
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                        gotoPage(pageNumber);
                                    }}
                                    style={{ maxWidth: "70px" }}
                                /> of {pageOptions.length} 
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage} >
                                <FaAngleRight />
                            </button>
                            <button className="btn btn-sm btn-light" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >
                                <FaAngleDoubleRight />
                            </button>
                        </div>
                        <span className="d-flex align-items-center">
                            <span className="text-nowrap page_size">
                                Rows Per page:{" "}
                            </span>
                            <select
                                className="form-select ml-2 custom-select py-1"
                                value={pageSize}
                                onChange={(e) =>
                                    setPageSize(Number(e.target.value))
                                }
                            >
                                {[10, 25, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                </div>
                <ActiveShipping setLoader={setLoader} setSuccessMsg={setSuccessMsg} show={shipPopup} onHide={() => setShipPopup(false)} data={shipStone} userinfo={userinfo} setTemp={setTemp} temp={temp} totalDiamonds={totalDiamonds} totalPrice={totalPrice} totalDollarCarat={totalDollarCarat} totalCarat={totalCarat}/>
                <RemoveStones setLoader={setLoader} setSuccessMsg={setSuccessMsg} show={removeStonePopup} onHide={() => setRemoveStonePopup(false)} data={shipStone} userinfo={userinfo} setTemp={setTemp} temp={temp} totalDiamonds={totalDiamonds} totalPrice={totalPrice} totalDollarCarat={totalDollarCarat} totalCarat={totalCarat}/>
            </div>
            : 
                <SearchTableSkeleton />
            }
        </>
    );
};
