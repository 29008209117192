import React, { useState, useEffect } from "react";
import { Modal} from "react-bootstrap";
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../env_config';

export const PermissionPopup = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] =useState([]);
    const [loading, setLoading] = useState(false);
    const [update,setUpdate] = useState(false)

    useEffect(() => {
        setShowPopup(props.show);
    }, [props.show]);

    useEffect(() => {
        let isMounted = true;
         if(props.data.subSuppId){
             axios({
                 method: "GET",
                 url: `${NODE_API_URL}/SUPP/fetch-supplier-resource/${props.data.subSuppId}`,
                 headers: {
                   "Access-Control-Allow-Origin": "*",
                   "Content-Type": "application/json",
                   "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                 },
             }).then((res) => {
                 if (res && res.data.data) {
                     isMounted && setData(res.data.data);
                   }
               }).catch((res) => {
                 if(res?.response?.data?.message === "UnAuthorized!"){
                   localStorage.clear();
                   window.location.replace(`${BASE_URL}/logout`);
               }
             });
         } 
          
    }, [props.data.subSuppId,update]);

    const disableLoading = () => {
        setLoading(false);
    };

    const closeModel = () => {
        props.onHide();
        setShowPopup(false);
    }; 

    const UpdateChangedValue = (value,val) => {
        const finalData ={
            "sub_supp_id":props.data.subSuppId,
            "resource_id":value.id,
            "edit_permission":value.checked ? "1" : "0",
            "updated_by":props.data.suppId
        }

        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/update-supp-permission`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
            data: finalData,
        }).then((res) => {
            disableLoading();
            if (res && res.data) {
                setUpdate(!update)
            }
        }).catch(error => {
            disableLoading();
            if(error?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
            }
        })
    }
    const UpdateReadChangedValue = (value) => {

        const finalData ={
            "sub_supp_id":props.data.subSuppId,
            "resource_id":value.id,
            "read_permission":value.checked ? "1" : "0",
            "updated_by":props.data.suppId
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/update-supp-permission`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
            data: finalData,
        }).then((res) => {
            disableLoading();
            if (res && res.data) {
                setUpdate(!update)
            }
        }).catch(error => {
            disableLoading();
            if(error?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
            }
        })

    }

    return (
        <div>
            <Modal
                show={showPopup}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className="font-weight-bold ">
                            Change Permissions - <span className="text-primary ml-2">{props.data.userName}</span>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row form">
                    <div className="col-12">
                    
                        <table className="table table-bordered permissionTable">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className="text-center">Page Name</th>
                                    <th className="text-center">View Permission</th>
                                    <th className="text-center">Edit Permission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length !== 0 ? data.map((val) => {
                                    return(
                                        <tr key={val.id}>
                                            <td>{val.pageTitle}</td>
                                            <td>
                                                <label className="checkbox justify-content-center">
                                                    <input
                                                        type="checkbox"
                                                        name={`view_${val.id}`}
                                                        id={val.id}
                                                        checked={val.readPermission.toString() === "1" && true}
                                                        onChange={(e) => UpdateReadChangedValue(e.target)}
                                                        value={val.readPermission}
                                                    />
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td>
                                                <label className="checkbox justify-content-center">
                                                    <input
                                                        type="checkbox"
                                                        name={`edit_${val.id}`}
                                                        id={val.id}
                                                        checked={val.editPermission.toString() === "1" && true}
                                                        onChange={(e,val) => UpdateChangedValue(e.target,val)}
                                                        value={val.editPermission}
                                                    />
                                                    <span></span>
                                                </label>
                                                
                                            </td>
                                        </tr>
                                    );    
                                }) : <tbody><tr><td className="text-center border-bottom" colSpan="100%">No record found</td></tr></tbody> }
                                
                            </tbody>
                        </table>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-3 justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9"
                        onClick={() => closeModel(props)}
                    >
                        <span>Close</span>
                    </button>
                    
                </Modal.Footer>
            </Modal>
        </div>
    );
};
