import React from "react";
import { Grid, Typography, InputLabel } from "@material-ui/core";
import { InputField, PhoneInput } from "../FormFields";
// import { at } from 'lodash';
// import { useField } from 'formik';

export default function ShippingInfoForm(props) {
    // const [field, meta] = useField("shipping_address1");

    // function _renderHelperText() {
    //   const [touched, error] = at(meta, 'touched', 'error');
    //   if (touched && error) {
    //     return error;
    //   }
    // }
    const {
        formField: {
            comp_name_1,
            person_name_1,
            designation_1,
            email_id_1,
            contact_no_1,
            comp_name_2,
            person_name_2,
            designation_2,
            email_id_2,
            contact_no_2,
        },
        // values,
        setFieldValue
    } = props;

    return (
        <React.Fragment>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid className='text-center' item xs={12} sm={7}>
                    <h3 className="card-label font-weight-bolder text-dark mb-0">Company Reference - 1</h3>
                    <span className="text-muted font-weight-bold font-size-sm ">Update your Company Reference - 1</span>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{comp_name_1.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={comp_name_1.name}
                                label={comp_name_1.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{person_name_1.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={person_name_1.name}
                                label={person_name_1.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{designation_1.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={designation_1.name}
                                label={designation_1.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{email_id_1.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={email_id_1.name}
                                label={email_id_1.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">Contact number:</Grid>
                        <Grid item xs={9}>
                            <PhoneInput name={contact_no_1.name} label={contact_no_1.label} fullWidth onChange={(value) => setFieldValue("contact_no_1",`+${value}`)}/>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
            </Grid>
         
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid className='text-center' item xs={12} sm={7}>
                    <h3 className="card-label font-weight-bolder text-dark mb-0 mt-8">Company Reference - 2</h3>
                    <span className="text-muted font-weight-bold font-size-sm ">Update your Company Reference - 2</span>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{comp_name_2.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={comp_name_2.name}
                                label={comp_name_2.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{person_name_2.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={person_name_2.name}
                                label={person_name_2.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{designation_2.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={designation_2.name}
                                label={designation_2.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">{email_id_2.label}</Grid>
                        <Grid item xs={9}>
                            <InputField
                                name={email_id_2.name}
                                label={email_id_2.label}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={3} className="font-weight-bold font-size-lg">Contact number:</Grid>
                        <Grid item xs={9}>
                            <PhoneInput name={contact_no_2.name} label={contact_no_2.label} fullWidth onChange={(value) => setFieldValue("contact_no_2",`+${value}`)}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
