/**
 * Entry application component used to compose providers and render Routes.
 * */

import React,{useEffect} from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import moment from "moment";
import packageJson from "../../package.json";
// import { RemoveCookies } from "../_metronic/_helpers/Cookies";
// import { BASE_URL } from "../env_config";
const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};
export default function App({ store, persistor, basename }) {
  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {

        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name);
              }
            });
            window.location.reload(true);
          }
        }
      });
  }, []);
  // useEffect(() => {
  //   const  url = window.location.pathname;
  //   const segment = url.split("/")[2]
  //   const token = url.split("/")[3]
  //   const hreff = window.location.href
  //   const hreffsegment = hreff.split("/")[2]
  //   console.log(hreffsegment)
  //   if (segment === "verifyaccount"){
  //     localStorage.clear('persist:suppAuthTemp');
  //     RemoveCookies("resData")
  //     console.log("vvvvvv")
  //     if (hreffsegment === "localhost:3000"){
  //       window.location.replace(`http://localhost:3000/auth/verifyswitch/${token}`)
  //     } else {
  //       window.location.replace(`${BASE_URL}/auth/verifyswitch/${token}`)
  //     }
  //   } else if(segment === "rejectaccount"){
  //       localStorage.clear('persist:suppAuthTemp');
  //       console.log("rrrrrr")
  //     RemoveCookies("resData")
  //     if (hreffsegment === "localhost:3000"){
  //       window.location.replace(`http://localhost:3000/auth/rejectswitch/${token}`)
  //     } else {
  //       window.location.replace(`${BASE_URL}/auth/rejectswitch/${token}`)
  //     }
     
  //   }
  // },[])
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
