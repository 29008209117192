import React, { useMemo, useState,useEffect } from "react";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
// import DummyData from "../../dummyData/approvedfeed.json";
import { approvedFeedColumn } from "./Columns";
import { GlobleFilter } from "../../tableFilter";
import {FeedCancel} from "../../popup/FeedCancel"
import { Dropdown, Modal } from 'react-bootstrap';
import { FaSortDown,FaSortUp,FaAngleLeft,FaAngleRight,FaAngleDoubleLeft,FaAngleDoubleRight,FaEllipsisV} from "react-icons/fa";
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../../env_config';
import DiamFeedSk from "../../SkeletonLoaders/DiamondFeed";

export const ApprovedFeed = ({userinfo,subId,editPerm}) => {
    const [showDiamData,setShowDiamData] = useState([])
    const [msg, setMsg] = useState(false)
    const [data,setData] = useState([])
    const [loader,setLoader] = useState(true)
    const [showFeedCancelPopup,setShowFeedCancelPopup] = useState(false)
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const handleClose = () => setCustomizeTable(false);

    const columns = useMemo(() => approvedFeedColumn(userinfo), [userinfo]);
    const [temp,setTemp] = useState(true)
    // const data = useMemo(() => DummyData, []);
// 
    useEffect(() => {
        const finalData = {
            "supplier_id":userinfo.id,
            "req_status":1
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/SUPP/diamond-feed-per-req`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
            data: finalData,
        }).then((res) => {
            setLoader(false)
            if (res && res.data) {
                setData(res.data.data)
            }
            
        }).catch((res) => {
            setLoader(false)
            setData([])
            if(res?.response?.data?.message === "UnAuthorized!"){
                localStorage.clear();
                window.location.replace(`${BASE_URL}/logout`);
            }
        });
    },[userinfo.id,temp])

    const CancelFeed = (row) =>{
        setShowFeedCancelPopup(true)
        setShowDiamData(row.original)
    }

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
        state: { globalFilter, pageIndex, pageSize },
        allColumns,
        getToggleHideAllColumnsProps,
    } = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    ...columns,
                    {
                        Header: () => "Action",
                        id: "expander",
                        Cell: ({ row }) => (
                            <>
                            {editPerm || !subId ?
                                <button className="btn btn-sm btn-danger cancelApiBtn" onClick={() => CancelFeed(row)}>Cancel API</button>
                                :"-"
                            }
                            </>
                        ),
                        className:"text-center align-middle cancelBtnTd",
                        maxWidth:"120px",
                        minWidth:"120px"
                    },
                ];
            });
        }
    );
    return (
        <>{!loader ?
            <div className="card-body p-0">

                <div className="w-100 d-flex justify-content-between align-items-center">
                    <div className="font-weight-bolder font-size-h5 text-success">Approved Request</div>
                    <div className="d-flex" style={{width:"200px"}}>
                        <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter}  />
                        <div className="search_result_dropdown">
                            <Dropdown >
                                <Dropdown.Toggle size="sm" variant="clear">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Modal
                                size="sm"
                                show={CustomizeTable}
                                onHide={() => setCustomizeTable(false)}
                                aria-labelledby="Customize-table"
                            >
                                <Modal.Header className="px-5 py-3" closeButton>
                                    <Modal.Title id="Customize-table">
                                        Customize Columns
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2 searchResult">
                                    {/* <PerfectScrollbar
                                        options={perfectScrollbarOptions}
                                        className="scroll"
                                        style={{ maxHeight: "400px", position: "relative" }}
                                    > */}
                                    {/* <div  className="" > */}
                                        <div>
                                            <label className="checkbox my-2 border p-2">
                                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                <span className="mr-2"></span>
                                                Show All
                                            </label> 
                                        </div>
                                        {
                                            allColumns.map(column =>(
                                                <div key={column.id} className={`${column.id}`}>
                                                    <label className="checkbox my-2 border p-2">
                                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                        <span className="mr-2"></span>
                                                        {column.Header}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    {/* </div> */}
                                    {/* </PerfectScrollbar> */}
                                </Modal.Body>
                                <Modal.Footer className="px-5 py-3">
                                    {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                {msg ? (
                    <div className="my-5 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            API Cancelled Successfully
                        </div>
                    </div>
                ) : (
                   ""                    
                )}
                <div className="table-responsive custom-scrollbar w-100 mt-4">
                    <table className="table table-striped table-hover custom_datatable api_datatable approvedApiTable" {...getTableProps()} >
                            <thead className="custom_datatable_head">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(
                                        (column, i) => (
                                            <th key={i} className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                                <span className="text-secondary">
                                                    {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                </span>
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {data.length !== 0 ? page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={row.id}>
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <td className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className,})}key={i}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }) :
                            <tr>
                                <td className="text-center align-middle"colSpan="100%">
                                    No data found
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="table_page_length">
                        Page {pageIndex + 1} of {pageOptions.length}{" "}
                    </span>
                    <div>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage} >
                            <FaAngleDoubleLeft />
                        </button>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage} >
                            <FaAngleLeft />
                        </button>
                        <span className="font-weight-bold text-muted">
                            <input
                                className="form-control d-inline-block form-control-sm"
                                type="number"
                                value={pageIndex + 1}
                                // defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(pageNumber);
                                }}
                                style={{ maxWidth: "70px" }}
                            /> of {pageOptions.length} 
                        </span>
                        <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage} >
                            <FaAngleRight />
                        </button>
                        <button className="btn btn-sm btn-light" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >
                            <FaAngleDoubleRight />
                        </button>
                    </div>
                    <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">
                            Rows Per page:{" "}
                        </span>
                        <select
                            className="form-select ml-2 custom-select py-1"
                            value={pageSize}
                            onChange={(e) =>
                                setPageSize(Number(e.target.value))
                            }
                        >
                            {[10, 25, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                <FeedCancel setMsg={setMsg} temp={temp} setTemp={setTemp} show={showFeedCancelPopup} onHide={() => setShowFeedCancelPopup(false)} data={showDiamData} userinfo={userinfo} />
            </div> :
                <DiamFeedSk />
                // <div className="card-body p-0 d-flex justify-content-center font-weight-bold font-size-lg">Loading...<div className="spinner spinner-primary ml-5"></div></div>
            }
        </> 
    );
};
