/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
import React,{useState,useEffect} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { SwalWarn } from "../../../../_partials/popup";
import { AddAccount } from "../../../../_partials/popup/AddAccount";
import {SetCookies,GetCookies,RemoveCookies} from "../../../../_helpers/Cookies";
import Cookies from "js-cookie"

import {
  FaUpload,
  FaSearch,
  FaExclamationTriangle,
  FaHistory,
  FaPause,
  FaCheckDouble,
  FaChartBar,
  FaInfo ,
  FaUserPlus
} from 'react-icons/fa';
import {MdSpaceDashboard} from  'react-icons/md';
import Dropdown from "react-bootstrap/Dropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../../../env_config';
import { AiOutlineUserSwitch } from "react-icons/ai";

export function AsideMenuList({ layoutProps,userinfo,subId }) {
  const [swalWarnOpen,setSwalWarnOpen] = useState(false)
  const [alertMessage,setAlertMessage] = useState()
  const [addAccountOpen,setAddAccountOpen] = useState(false)
  const [accountData,setAccountData] = useState([])
  const [editPerm, setEditPerm] = React.useState(0)
  const [readPerm, setReadPerm] = React.useState(0)
  const [loader,setLoader] = React.useState(true)
  const [secId,setSecId] = React.useState()
  const [primeData,setPrimeData] = React.useState([])
  console.log(subId)
  useEffect(() => {
    let isMounted = true;

        if (subId){
            setLoader(true)
            const permiFetchData = {
              "sub_supp_id":subId,
              "resource_id":13
            };
            axios({
              method: "GET",
              url: `${NODE_API_URL}/SUPP/fetch-supp-permission`,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
              params: permiFetchData,
            }).then((res) => {
                setLoader(false)
                if (res && res.data) {
                    isMounted && setEditPerm(res.data.data[0].editPermission);
                    isMounted && setReadPerm(res.data.data[0].readPermission); 
                  }
            }).catch((res) => {
                setLoader(false)
                if(res?.response?.data?.message === "UnAuthorized!"){
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });
          }else{
            setLoader(false)
          }

        axios({
          method: "GET",
          url: `${NODE_API_URL}/SUPP/fetch-switch-app`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
          },
        }).then((res) => {
          // console.log(res)
            if (res && res.data) {
                isMounted && setAccountData(res.data.data);
            }
        }).catch(res => {
          if(res?.response?.data?.message === "UnAuthorized!"){
            localStorage.clear();
            window.location.replace(`${BASE_URL}/logout`);
          }
      });
    GetCookies("SecId").then(response => {
      if(response){
        setSecId(response)
      }
    })
    GetCookies("PrimeData").then(response => {
      if(response){
        setPrimeData(JSON.parse(response))
      }
    })
     
  }, [userinfo.id,subId]);

  const addAccount  = () => {
    setAddAccountOpen(true)
  }
  const returnAccount  = () => {
    GetCookies("PrimeData").then(response => {
      if(response){
        localStorage.setItem("authToken",Cookies.get("AuthSwitchToken"));
        SetCookies("resData",response)
        RemoveCookies("SecData")
        RemoveCookies("SecId")
        setTimeout(() => {
          window.location.reload()
        },500)
      }

    })
  }
  const switchAccount  = (props) => {
  
    axios({
      method: "GET",
      url: `${NODE_API_URL}/SUPP/login-switch-supp/${props.id}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
      },
    }).then((res) => {
        if (res && res.data) {
          const resData = JSON.stringify(res.data.data[0])
          
          SetCookies("resData",resData)
          SetCookies("SecData",resData)
          SetCookies("SecId",res.data.data[0].id)
          localStorage.setItem("authToken", res.data.token);
          setTimeout(() => {
            window.location.reload()
          },500)
        }
    }).catch(res => {
      setSwalWarnOpen(true)
      setAlertMessage(res?.response?.data?.message)
      if(res?.response?.data?.message === "UnAuthorized!"){
        localStorage.clear();
        window.location.replace(`${BASE_URL}/logout`);
      }
    });
  }
  const profileValidation = () => {
    setSwalWarnOpen(true)
    if(((!userinfo.designation) || (!userinfo.address) || !userinfo.cPartnerDoc) || (!userinfo.cDocumentType) || (!userinfo.cPartnerName) || (!userinfo.c_register_upload) || (!userinfo.cRegisterNo)){
      setAlertMessage("Your profile is not Activated. Please Complete your profile.")
    }else {
      setAlertMessage("Thank you for completing your profile. Please wait for approval, until then you can start uploading your stock.")
    }
  }
  const activeValidation = () => {
    setSwalWarnOpen(true)
    setAlertMessage("Your account is Inactive, please contact your purchase executive.")
  }
  const freezeValidation = () => {
    setSwalWarnOpen(true)
    setAlertMessage("Your account is Freeze, please contact your purchase executive.")
  }

  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  if (!userinfo){
    return
  }
 
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item`} aria-haspopup="true" >
          {userinfo.type ==="Natural" ?
            <div className="menu-link align-items-center py-0" >
              <span className="svg-icon menu-icon">
                <img className="mr-3 rounded" width={24} alt="dimond_type" src={toAbsoluteUrl("/media/natural-diamond.png")}/>
              </span>
              <span className="menu-text font-weight-bold font-size-h6 text-white">Natural</span>
              {((readPerm && editPerm) || !subId) && userinfo.isPending !== 1 ?
              <Dropdown drop="down" alignRight className="switchDropdown">
                <Dropdown.Toggle
                  id="dropdown-toggle-user-switch"
                >
                  <OverlayTrigger placement="top" overlay={<Tooltip >Click to Switch account</Tooltip>} >
                    <span className="text-hover-warning font-size-h2"> <AiOutlineUserSwitch /> </span>
                  </OverlayTrigger>
                </Dropdown.Toggle>
                <Dropdown.Menu >
                  {secId ? 
                    <Dropdown.Item className="font-weight-bold d-block" onClick={()=> returnAccount()}>
                        Return to{" "}
                        <div>
                          
                            {primeData.type === "Natural"
                              ? 
                                <OverlayTrigger placement="top" overlay={<Tooltip >Natural</Tooltip>} >
                                  <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/natural-diamond.png")}/>
                                </OverlayTrigger>
                              :
                                <OverlayTrigger placement="top" overlay={<Tooltip >Lab grown</Tooltip>} >
                                  <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/lab-grown-diamond.png")}/>
                                </OverlayTrigger>
                            }{" "}
                            {primeData.supplier_name}
                        </div>
                    </Dropdown.Item>
                  :
                    <>
                      {accountData.length !== 0 ?
                          accountData.map((val) => {
                              return(
                                <Dropdown.Item key={val.id} className="font-weight-bold align-items-center" onClick={()=> switchAccount(val)}>
                                
                                {val.type === "Natural"
                                  ? 
                                    <OverlayTrigger placement="top" overlay={<Tooltip >Natural</Tooltip>} >
                                      <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/natural-diamond.png")}/>
                                    </OverlayTrigger>
                                  :
                                    <OverlayTrigger placement="top" overlay={<Tooltip >Lab grown</Tooltip>} >
                                      <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/lab-grown-diamond.png")}/>
                                    </OverlayTrigger>
                                }{" "}
                                {val.supplierName}
                              </Dropdown.Item>
                              );    
                          }) 
                      : "" }
                      {accountData.length < 3 ? <Dropdown.Item className="font-weight-bold align-items-center border-top bg-primary-o-20 text-primary" onClick={()=> addAccount(userinfo)}><FaUserPlus className="mr-2 font-size-h2" />Connect Supplier Account</Dropdown.Item> :""}
                    </>
                  }
                </Dropdown.Menu>
              </Dropdown> : ""}
            </div>
         :
            <div className="menu-link align-items-center py-0" >
              <span className="svg-icon menu-icon">
                <img className="mr-3 rounded" width={24} alt="dimond_type" src={toAbsoluteUrl("/media/lab-grown-diamond.png")}/>
              </span>
              <span className="menu-text font-weight-bold font-size-h6 text-white">Lab-Grown</span>
              {((readPerm && editPerm) || !subId) && userinfo.isPending !== 1 ?
              <Dropdown drop="down" alignRight className="switchDropdown">
                <Dropdown.Toggle
                  id="dropdown-toggle-user-switch"
                >
                  <OverlayTrigger placement="top" overlay={<Tooltip >Click to Switch account</Tooltip>} >
                    <span className="text-hover-warning font-size-h2"> <AiOutlineUserSwitch /> </span>
                  </OverlayTrigger>
                </Dropdown.Toggle>
                <Dropdown.Menu >
                  {secId ? 
                    <Dropdown.Item className="font-weight-bold d-block" onClick={()=> returnAccount()}>
                        Return to{" "}
                        <div>
                            {primeData.type === "Natural"
                              ? 
                                <OverlayTrigger placement="top" overlay={<Tooltip >Natural</Tooltip>} >
                                  <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/natural-diamond.png")}/>
                                </OverlayTrigger>
                              :
                                <OverlayTrigger placement="top" overlay={<Tooltip >Lab grown</Tooltip>} >
                                  <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/lab-grown-diamond.png")}/>
                                </OverlayTrigger>
                            }{" "}
                            {primeData.supplier_name}
                        </div>
                    </Dropdown.Item>
                  :
                    <>
                      {accountData.length !== 0 ?
                          accountData.map((val) => {
                              return(
                                <Dropdown.Item key={val.id} className="font-weight-bold align-items-center" onClick={()=> switchAccount(val)}>
                                
                                {val.type === "Natural"
                                  ? 
                                    <OverlayTrigger placement="top" overlay={<Tooltip >Natural</Tooltip>} >
                                      <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/natural-diamond.png")}/>
                                    </OverlayTrigger>
                                  :
                                    <OverlayTrigger placement="top" overlay={<Tooltip >Lab grown</Tooltip>} >
                                      <img className="mr-2 rounded" width={15} alt="dimond_type" src={toAbsoluteUrl("/media/lab-grown-diamond.png")}/>
                                    </OverlayTrigger>
                                }{" "}
                                {val.supplierName}
                              </Dropdown.Item>
                              );    
                          }) 
                      : "" }
                      {accountData.length < 3 ? <Dropdown.Item className="font-weight-bold align-items-center border-top bg-primary-o-20 text-primary" onClick={()=> addAccount(userinfo)}><FaUserPlus className="mr-2 font-size-h2" />Connect New Account</Dropdown.Item> :""}
                    </>
                  }
                </Dropdown.Menu>
              </Dropdown> :""}
            </div>
          }
        </li>
        <div className="menu-separator"></div>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-arrange.svg")} /> */}
              <MdSpaceDashboard />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {userinfo.status === 1 ?
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/upload-diamond", false)}`}
              aria-haspopup="true"
              onClick={() => activeValidation() }
            >
               <span className="menu-link">
                <span className="svg-icon menu-icon">
                  <FaUpload /> 
                </span>
                <span className="menu-text">Upload Diamonds</span>
              </span>
            </li>
            {/*end::1 Level*/}
          </>
        :
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/upload-diamond", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/upload-diamond">
                <span className="svg-icon menu-icon">
                  <FaUpload /> 
                </span>
                <span className="menu-text">Upload Diamonds</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        }
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/search-diamond", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/search-diamond">
            <span className="svg-icon menu-icon">
              <FaSearch /> 
            </span>
            <span className="menu-text">Search Diamonds</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        
        {/*end::1 Level*/}
        {userinfo.status === 1 ?
          <>
           {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/invalid-diamond", false)}`}
          aria-haspopup="true"
          onClick={() => activeValidation() }
        >
          <span className="menu-link">
            <span className="svg-icon menu-icon">
              <FaExclamationTriangle /> 
            </span>
            <span className="menu-text">Invalid Diamonds</span>
          </span>
        </li>
          </>
        :
          <>
           {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/invalid-diamond", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/invalid-diamond">
            <span className="svg-icon menu-icon">
              <FaExclamationTriangle /> 
            </span>
            <span className="menu-text">Invalid Diamonds</span>
          </NavLink>
        </li>
        {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/upload-history", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/upload-history">
                <span className="svg-icon menu-icon">
                  <FaHistory /> 
                </span>
                <span className="menu-text">Upload History</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        }
        {userinfo.isPending === 1 ? 
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/hold-request", false)}`}
              aria-haspopup="true"
              onClick={() => profileValidation() }
            >
              <span className="menu-link">
                <span className="svg-icon menu-icon">
                  <FaPause /> 
                </span>
                <span className="menu-text">Hold Request</span>
              </span>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/confirm-history", false)}`}
              aria-haspopup="true"
              onClick={() => profileValidation() }
            >
              <span className="menu-link">
                <span className="svg-icon menu-icon">
                  <FaCheckDouble /> 
                </span>
                <span className="menu-text">Confirm History</span>
              </span>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/sales-history", false)}`}
              aria-haspopup="true"
              onClick={() => profileValidation() }
            >
              <span className="menu-link">
                <span className="svg-icon menu-icon">
                  <FaChartBar /> 
                </span>
                <span className="menu-text">Sales History</span>
              </span>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/diamond-feed", false)}`}
              aria-haspopup="true"
              onClick={() => profileValidation() }
            >
              <span className="menu-link">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/feed.svg")} />
                </span>
                <span className="menu-text">Diamond Feed</span>
              </span>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/} 
          </>
        :
         <>
            
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/hold-request", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/hold-request">
                <span className="svg-icon menu-icon">
                  <FaPause /> 
                </span>
                <span className="menu-text">Hold Request</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/confirm-history", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/confirm-history">
                <span className="svg-icon menu-icon">
                  <FaCheckDouble /> 
                </span>
                <span className="menu-text">Confirm History</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/sales-history", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/sales-history">
                <span className="svg-icon menu-icon">
                  <FaChartBar /> 
                </span>
                <span className="menu-text">Sales History</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {userinfo.status === 1 ?
              <li
                className={`menu-item ${getMenuItemActive("/diamond-feed", false)}`}
                aria-haspopup="true"
                onClick={() => activeValidation() }
              >
                <span className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/feed.svg")} />
                  </span>
                  <span className="menu-text">Diamond Feed</span>
                </span>
              </li>
            :
              <>
                {userinfo.stockStatus === 1 ?
                  <li
                    className={`menu-item ${getMenuItemActive("/diamond-feed", false)}`}
                    aria-haspopup="true"
                    onClick={() => freezeValidation() }
                  >
                    <span className="menu-link">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/feed.svg")} />
                      </span>
                      <span className="menu-text">Diamond Feed</span>
                    </span>
                  </li>
                :
                  <li
                    className={`menu-item ${getMenuItemActive("/diamond-feed", false)}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/diamond-feed">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/feed.svg")} />
                      </span>
                      <span className="menu-text">Diamond Feed</span>
                    </NavLink>
                  </li>
                }
              </>
            }
            
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
         </>
        }
        <li
          className={`menu-item ${getMenuItemActive("/help", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/help">
            <span className="svg-icon menu-icon">
              <FaInfo /> 
            </span>
            <span className="menu-text">Help</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>
      <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
      <AddAccount show={addAccountOpen} setAddAccountClose={() => setAddAccountOpen(false)}  userinfo={userinfo} addClose={() => setAddAccountOpen(false)} /> 
      {/* end::Menu Nav */}
    </>
  );
}
