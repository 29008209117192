/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { BASE_URL, NODE_API_URL } from "../../../../env_config";
import axios from "axios";
import {
    FaUserPlus
} from "react-icons/fa";
import { AddAccount } from "../../popup/AddAccount";
import { ManageAccountTable } from "./ManageAccount/ManageAccountTable";

export function ManageAccount({ userinfo,subId }) {
    const [accountData, setAccountData] = useState([]);
    const [editPerm, setEditPerm] = React.useState(0)
      const [addAccountOpen,setAddAccountOpen] = useState(false)

    useEffect(() => {
        let isMounted = true;
        axios({
            method: "GET",
            url: `${NODE_API_URL}/SUPP/fetch-switch-app`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
              },
        })
            .then((res) => {
                // console.log(res);
                if (res && res.data) {
                    isMounted && setAccountData(res.data.data);
                }
            })
            .catch((res) => {
                if(res?.response?.data?.message === "UnAuthorized!"){
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });

            if (subId){
                const permiFetchData = {
                  "sub_supp_id":subId,
                  "resource_id":14
                };
                axios({
                  method: "GET",
                  url: `${NODE_API_URL}/SUPP/fetch-supp-permission`,
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                  },
                  data: permiFetchData,
                }).then((res) => {
                    if (res && res.data) {
                        isMounted && setEditPerm(res.data.data[0].editPermission);
                    }
                }).catch(error => {
                    if(error?.response?.data?.message === "UnAuthorized!"){
                        localStorage.clear();
                        window.location.replace(`${BASE_URL}/logout`);
                    }
                });
              }
    }, [userinfo.id,subId]);
    const addAccount  = () => {
        setAddAccountOpen(true)
      }
   
    return (
       <>
            <div className="card card-custom">
                {/* begin::Header */}
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="card-label font-weight-bolder text-dark">
                            Manage Switch Accounts Setting
                        </h3>
                        <span className="text-muted font-weight-bold font-size-sm mt-1">
                            Manage your Switch accounts
                        </span>
                    </div>
                    {!subId || editPerm?
                    <div className="card-toolbar">
                        {accountData.length < 3 ? 
                            <button className="btn btn-light-primary" onClick={()=> addAccount(userinfo)}><FaUserPlus className="mr-2 font-size-h2" />Connect Supplier Account</button>
                        :""}
                    </div>
                    :""}
                </div>
                {/* end::Header */}
                {/* begin::Form */}
                <ManageAccountTable accountData={accountData} userinfo={userinfo} subId={subId}/>
            </div>
            <AddAccount show={addAccountOpen} setAddAccountClose={() => setAddAccountOpen(false)}  userinfo={userinfo} addClose={() => setAddAccountOpen(false)} /> 
       </>
    );
}
