import React from "react";
import { Link } from "react-router-dom";
import { HiRefresh } from "react-icons/hi";
import {BASE_URL, NEW_BASE_URL_FILE} from "../../../env_config"
import axios from "axios";
export const FtpMode = ({userinfo,editPerm,readPerm,subId}) => {

    const [errorMsg, setErrorMsg] = React.useState('')
    const [count, setCount] = React.useState()
    const[loading,setLoading]= React.useState(false)
    const Refresh= ()=> {
        setLoading(true)
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${NEW_BASE_URL_FILE}/admin/ftpFileManual/${userinfo.id}`,
          headers: { 
            'Cookie': 'ci_session=beb77d8a0936e2b92032de323be390db9d4e038c',
            // "Access-Control-Allow-Origin": "*"
          }
        };
        
        axios.request(config)
            .then((response) => {
                setLoading(false)
                if(response && response.data && response.data.successdata >= 0){
                    setCount(`Valid Count : ${response.data.successdata} Invalid Count : ${response.data.invaliddata}`)
                    setErrorMsg()
                } else {
                    setErrorMsg("File not found")
                    setCount()
                }
                // if(finalresdata === "File not found"){
                //     setErrorMsg(JSON.stringify(response.data))
                //     setCount()
                // }
            })
            .catch((error) => {
                setLoading(false)
                // console.error(error);
            });
        }
    return (
        <>
            <div className="card-body pb-10">
                <h3 className="mb-1">FTP Details</h3>
                <span className="text-muted">
                    Upload your Diamond inventory using FTP, by using the credentials mentioned below.
                    To know how to upload using FTP you can <Link to="/ftpmode-help">Read FTP Documentation</Link> in Help section.
                </span>
                <div className="row mt-8">
                    <div className="col-md-6">
                        <div className="border border-secondary rounded p-5 p-md-8 custom-shadow-sm bg-primary-o-20">
                            <div className="row mb-3">
                                <div className="col-md-4 font-weight-bold">FTP File Name:</div>
                                <div className="col-md-8">Stock.CSV</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4 font-weight-bold">FTP Hostname:</div>
                                <div className="col-md-8">{userinfo.ftpHost}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4 font-weight-bold">FTP Username:</div>
                                <div className="col-md-8">{userinfo.ftpUsername}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4 font-weight-bold">FTP Password:</div>
                                <div className="col-md-8">{userinfo.ftpPassword}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 font-weight-bold">FTP Port:</div>
                                <div className="col-md-8">{userinfo.ftpPort}</div>
                            </div>
                        </div>
                    </div>
                    {userinfo.uploadMode === "FTP" ? 
                        <div className="col-md-6">
                            {errorMsg? (
                                <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                        {errorMsg}
                                    </div>
                                </div>
                            ) : (
                               null
                            )}
                            {count ?
                                <div className="mb-5 alert alert-custom alert-light-success alert-dismissible">
                                    <div className="alert-text font-weight-bold">
                                        <span className="font-weight-bold">{count}</span>
                                    </div>
                                </div> : " "
                            }
                            <h3 className="mb-1">Refresh</h3>
                            <span className="text-muted">
                                To Refresh the stock manually kindly click the button below.
                                {editPerm || !subId?
                                    <button type="submit" name="name" id="btnSubmit" className={`btn btn-primary ${loading ? "fileModeCursorDisable" : ""} px-8 mt-5 d-block`} onClick={() => Refresh()}><HiRefresh className="mr-2 font-size-h4" disabled={loading ? true : false} />
                                    Refresh
                                        {loading && (
                                            <span className="ml-3 spinner spinner-white"></span>
                                        )}
                                    </button>
                                : ""}
                                {/* <div className="mt-2 text-danger"><span className="font-weight-bold">Stock Expiry date :</span> 2023-06-27 20:31:02</div> */}
                            </span>
                        </div>
                    : " " }
                    <div className="col-md-8 mt-5">
                        <a href={`${BASE_URL}/media/sample/${userinfo.type === "Natural" ? "Natural_doc_file" : "Lab_doc_file"}.csv`} download="doc_upload_file.csv">Download Sample File from here.</a>
                    </div>
                    <div className="col-md-8 mt-5">
                        <div className="border-warning font-italic custom-shadow-sm py-4 font-size-lg rounded-left px-3 bg-warning-o-20" style={{borderLeft:"5px solid"}}><span className="font-weight-bold">Note:</span> If you would like to change your upload method, please contact your purchase representative</div>
                    </div>
                </div>
            </div>
        </>
    );
};
