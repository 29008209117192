import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button,Alert} from "react-bootstrap";
// import { toAbsoluteUrl } from "../../_helpers";
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../env_config';

const initialValues = {
    comments: "",
};

export const HoldCancel = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errmessage,setErrorMessage] = useState()
console.log(props)
    useEffect(() => {
        setShowPopup(props.show);
    }, [props.show]);

    const RegistrationSchema = Yup.object().shape({
        comments: Yup.string().required(
            "Please mention the reason in comment box."
        ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setSubmitting(true);
            // console.log(values);
            const finalData = {
                "userid":props.data?.clientId,
                "diamond_type": props.data?.diamondType === "L" ? "L" : "N" ,
                "certino":props.data?.certiNo,
                "statsusid":"0",
                "is_hold":`${props.data?.holdStatus}`,
                "hold_duration":props.data?.holdTime,
                "comment":values.comments,
                "confirm_id":props.data?.confirmGoodsId
            }
            // console.log(finalData)

            axios({
                method: "POST",
                url: `${NODE_API_URL}/SUPP/hold-approve-or-rej`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                  },
                data: finalData,
            }).then((res) => {
                disableLoading();
                // console.log(res)
                if (res && res.data) {
                //     // setData(res.data.data)
                // props.holdListData();
                    props.onHide();
                    // window.location.reload()
                    props.onHide();
                    props.setTemp(!props.temp)
                    props.setErrorMsg(`Your hold request cancelled for stone id : ${props.data?.loatNo}`)
                    props.setLoader(true)
                    setErrorMessage()
                }
            }).catch(error => {
                // console.log(error,"ERROR")
                disableLoading();
                setErrorMessage(error.response.data.error)
                if(error?.response?.data?.message === "UnAuthorized!"){
                    localStorage.clear();
                    window.location.replace(`${BASE_URL}/logout`);
                  }

            })
            // disableLoading();
        },
    });

    const closeModel = () => {
        props.onHide();
        setShowPopup(false);
    };

    return (
        <div>
            <Modal
                show={showPopup}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form
                    id="kt_login_signin_form"
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    onSubmit={formik.handleSubmit}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span className="font-weight-bolder text-warning">
                                Order ID :{props.data?.confirmGoodsId}
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                Are you sure you want to cancel the hold request?
                                {errmessage ? <Alert variant="danger"> {errmessage} </Alert>:""}
                            </div>
                            <div className="col-12 mt-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-1">
                                            <label htmlFor="comments">
                                                Comments
                                            </label>
                                            <textarea
                                                className={`form-control ${getInputClasses( "comments" )}`}
                                                name="comments"
                                                id="comments"
                                                placeholder="comments"
                                                rows="3"
                                                {...formik.getFieldProps( "comments" )}
                                            ></textarea>
                                            {formik.touched.comments &&
                                            formik.errors.comments ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.comments}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="py-3 justify-content-between">
                        <Button
                            variant="danger"
                            className="font-weight-bold"
                            onClick={() => closeModel(props)}
                        >
                            Close
                        </Button>
                        <button
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9"
                        >
                            <span>Yes</span>
                            {loading && ( <span className="ml-3 spinner spinner-white"></span>)}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
};
